<!--Component Header HTML-->
<nav class="navbar navbar-expand-sm fixed-top aba_ComponentHeader abaPdng_0 ababrdr_btm_1 shadow-sm">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="row">
      <!--Component Title Html-->
      <div class="col-xl-6 col-lg-6 col-md-5">
        <label class="abaLabel abaPdng_tp_22 abaPdng_lft_15">Hi, here's what you need to know</label>
      </div>
      <!--End of Component Title Html-->
      <!--Component Header Profile Html-->
      <div class="col-xl-6 col-lg-6 col-md-7">
        <a class="header-content profileAvatar">
          <i class="abaIcon" icon-name="iCircleUser-lg" (click)="userProfileMenuOpen();"></i>
        </a>
        <!--Profie Menu-->
        <div class="userProfileMenu" id="userProfileMenu">
          <!--Profile Avatar-->
          <div class="user_ProfileHeader">
            <span>
              <i class="abaIcon" icon-name="iCircleUser-xl"></i>
            </span>
            <span class="abaPdng_lft_10 abaPdng_tp_15">
              <label class="abaLabel abaPrimary">{{subscriptionUser}}</label> 
              <label class="abaLabel d-block">{{subscriptionEmail}}</label> 
            </span>
          </div>
          <!--end of Profile Avatar-->
          <!--Profile Content-->
          <div class="row user_ProfileMenuSection">
            <div class="col-md-4  user_ProfileContent" (click) ="onLogout()">
              <a (click)="userProfileMenuClose();">
                <i class="abaImgIcon defaultIcon" icon-name="iLogout-md"></i>
                <i class="abaImgIcon ActiveIcon" icon-name="iActiveLogout-md"></i>
                <label class="abaLabel d-block">Log Out</label>
              </a>
            </div>
            <div class="col-md-4 user_ProfileContent profile_PortalSwitch" *ngIf="hasStaffPortalAccess">
              <a href="{{routeToStaffPortal}}" target="_blank" (click)="userProfileMenuClose();">
                <label class="abaLabel abaWhite abaPdng_tp_35">Staff Portal</label>
              </a>
            </div>
          </div>
          <!--end of Profile Content-->
        </div>
        <!--Profile Dismissal-->
        <div class="profileMenu_Overlay" id="profileMenu_Overlay" (click)="userProfileMenuClose();"></div>
        <!--end of Profile Dismissal-->
        <!--End Of Profie Menu-->
        <a class="header-content">
          <i class="abaIcon" icon-name="iNotification-md"></i>
        </a>
        <a class="header-content ">
          <i class="abaIcon" icon-name="iMessage-md"></i>
        </a>

      </div>
      <!--Component Header Profile Html-->
    </div>
  </div>
</nav>
<!--End Of Component Header Html-->
