<!Doctype html>
<html>
<head>
</head>
<body id="abaUserBodyContent">
  <!--New Subscriber Setup HTML-->
  <div class="userSubscription_Container">    
      <!--Subscription Header -->
      <div class="userSubscription_Header text-center">
        <img src="../assets/SASS/Images/aloha-logo.png" alt="AlohaABA Logo" class="image-responsive float-md-left" />
        <span class="float-right d-none d-md-block">
          <i class="abaIcon abaUserContact" icon-name="iPhone-md"></i>
          <label class="abaUserHeader2 abaUserPrimary abaRegular"> +1(949)505-5801</label>
        </span>        
      </div>
      <!--End of Subscription Header -->
      <!-- Subscription Body -->
      <div class="userSubscription_Body">
        <!-- User Registration Form -->
        <form class="" *ngIf="formToggle" [attr.aria-pressed]="formToggle">
          <div class="row text-center">
            <!--New Customer Registration HTML-->
            <div class="col-10 offset-1 userRegisterForm col-lg-8 offset-lg-2" [formGroup]="accountInfoForm">
              <div class="row">
                <div class="col-12">
                  <header class="abaUserHeader2 abaUserPrimary px-2 px-md-0"> Try Aloha ABA free for 3 months </header>
                  <label class="abaUserLabel px-md-5 mx-md-5 pb-4 "> your few steps away from using fully loaded next generational practice management software</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>First Name *</mat-label>
                    <input matInput placeholder="" formControlName="firstName">
                    <span matPrefix><i class="abaIcon" icon-name="iUser-sm"></i></span>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Last Name *</mat-label>
                    <input matInput placeholder="" formControlName="lastName">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Email *</mat-label>
                    <input matInput formControlName="email">
                    <span matPrefix><i class="abaIcon" icon-name="iMessage-sm"></i></span>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone">
                    <span matPrefix><i class="abaIcon" icon-name="iPhone-sm"></i></span>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field>
                    <mat-label>Organization Name *</mat-label>
                    <input matInput placeholder="" formControlName="organizationName">
                    <span matPrefix><i class="abaIcon" icon-name="iOrganization-sm"></i></span>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field>
                    <mat-label>Site URL *</mat-label>
                    <input matInput placeholder="" formControlName="acronym">
                    <span matPrefix><i class="abaIcon" icon-name="iGlobe-sm"></i></span>
                    <span matSuffix>{{siteUrlSuffix}}</span>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field>
                    <input matInput placeholder="Enter your password" [type]="hide ? 'password' : 'text'">
                    <span matPrefix><i class="abaIcon" icon-name="iLock-sm"></i></span>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <i class="abaIcon" icon-name="iVisibilityOff-sm" *ngIf="!hide"></i>
                      <i class="abaIcon" icon-name="iVisibility-sm" *ngIf="hide"></i>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button mat-flat-button type="submit" class="w-100 mt-4" (click)="formToggle = !formToggle">SIGNUP FOR FREE</button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 py-4">
                  <label class="abaUserLabel px-md-5"> By clicking on "SIGNUP FOR FREE", you've read, understood and agreed to our <a class="abaUserLink abaBold" href="http://alohaaba.com/terms" target="_blank">Terms of Service</a> and <a class="abaUserLink abaBold" href="http://alohaaba.com/privacypolicy" target="_blank">Privacy Policy</a></label>
                </div>
              </div>
            </div>
            <!--end of New Customer Registration HTML-->
          </div>
        </form>
        <!-- End of User Registration Form -->
        <!-- Credit Card Verification Form -->
        <form class="" *ngIf="!formToggle">
          <div class="row text-center">
            <div class="col-10 offset-1 col-xl-6 offset-xl-3 text-left pb-md-5 pb-4">
              <a class="abaUserLink" (click)="formToggle = !formToggle">
                <i class="abaIcon abaUser_TabingIcon " icon-name="iLeftTagArrow-md"></i>
              </a>
            </div>
            <div class="col-10 offset-1 userCreditForm">
              <div class="row pb-4">
                <div class="col-12">
                  <header class="abaUserHeader3 abaBold abaUserPrimary "> Verify your credit card to complete signup</header>
                  <label class="abaUserLabel"> you will not be charged during the trail period.</label>
                  <label class="abaUserLabel">No strings attached and no sudden suprises. You can terminate at any time</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field>
                    <mat-label>Credit Card Number</mat-label>
                    <input matInput placeholder="">
                    <span matPrefix><i class="abaIcon" icon-name="iPayment-sm"></i></span>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <mat-label>MM/YY</mat-label>
                    <input matInput placeholder="">
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                  <mat-form-field>
                    <input matInput placeholder="cvv" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <i class="abaIcon" icon-name="iVisibilityOff-sm" *ngIf="!hide"></i>
                      <i class="abaIcon" icon-name="iVisibility-sm" *ngIf="hide"></i>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button mat-flat-button type="submit" class="w-100 mt-4">VALIDATE CARD & PROCEED</button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pt-4 pb-5 mb-md-5">
                  <label class="abaUserLabel px-md-3"> By clicking on "VALIDATE CARD & PROCEED", you've read, understood and agreed to our <a class="abaUserLink abaBold" href="http://alohaaba.com/terms" target="_blank">Terms of Service</a> and <a class="abaUserLink abaBold" href="http://alohaaba.com/privacypolicy" target="_blank">Privacy Policy</a></label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- End of Credit Card Verification Form -->
        <!-- Status Message Form-->
        <form class="userSuccessFrom d-none">
          <div class="row text-center">
            <div class="col-12">
              <header class="abaUserHeader3 abaBold abaUserPrimary "> It's almost done!</header>
              <label class="abaUserLabel"> Thank you for signing up. We're setting up your rightnow so can start using AlohaABA.</label>
              <label class="abaUserLabel">We'll apprecate patience.</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <img src="../assets/SASS/Images/userLoader.gif" class="py-5" />
              <label class="abaUserLabel d-block abaDisable">Loading...It might take few minutes/ It creates site with URL/ It creates dedicated database/ It creates organization/ It creates first staff</label>
            </div>
          </div>
        </form>
        <!-- End of Status Message Form-->
        <!-- Success Message Form-->
        <form class="userSuccessFrom d-none">
          <div class="row text-center">
            <div class="col-12">
              <header class="abaUserHeader3 abaBold abaUserPrimary "> Congratulations, everything went well!</header>
              <label class="abaUserLabel"> We've successfully completed your account setup. Now you can start using AlohaABA.</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <img src="../assets/SASS/Images/userLoader.gif" class="py-5" />
              <label class="abaUserLabel d-block abaDisable">You will be redirected to your AlohaABA account in XX second(s).</label>
            </div>
          </div>
        </form>
        <!-- End of Success Message Form-->
        <!-- Failure Message Form-->
        <form class="userSuccessFrom d-none">
          <div class="row text-center">
            <div class="col-12">
              <header class="abaUserHeader3 abaBold abaUserPrimary ">Sorry, something went wrong!</header>
              <label class="abaUserLabel"> We're unable to complete your account setup at this moment. Please try again after some time or contact us to have you onboarded.</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <img src="../assets/SASS/Images/userLoader.gif" class="py-5" />
              <label class="abaUserLabel d-block abaDisable">You will be redirected to AlohaABA website in XX second(s).</label>
            </div>
          </div>
        </form>
        <!-- End of Failure Message Form-->
        <!--Sales and Support-->
        <div class="row text-center">
          <div class="col-12 py-4 mt-md-4 pt-md-4 pb-md-0">
            <label class="abaUserLabel abaBold pb-3 pb-md-0"> SALES & SUPPORT:</label>
            <span class="pl-3 pb-3 pb-md-0 d-block d-md-inline-block"><i class="abaIcon abaUserSupport" icon-name="iPhone-xs"></i><label class="abaUserLabel pl-2"> +1(949)505-5801 </label></span>
            <span class="pl-3 pb-3 pb-md-0 d-block d-md-inline-block"><i class="abaIcon abaUserSupport" icon-name="iMessage-xs"></i><label class="abaUserLabel pl-2"> sales@alohaaba.com </label></span>
          </div>
        </div>
        <!--end of Sales and Support-->
      </div>
      <!--End of Subscription Body -->
      <!-- Subscription Footer -->
      <div class="userSubscription_Footer text-center">
        <label class="abaUserLabel float-md-left pb-3 pb-md-0 d-block">Copyright © <span class="abaUserPrimary">Aloha Practice Management, LLC</span></label>
        <a class="abaUserLabel float-md-right mr-4 mr-md-0 d-none d-md-block" href="http://alohaaba.com/privacypolicy" target="_blank">Privacy Policy</a>
        <a class="abaUserLabel float-md-right mr-4 mr-md-3" href="http://alohaaba.com/terms" target="_blank">Terms of Service</a>
        <a class="abaUserLabel d-md-none" href="http://alohaaba.com/privacypolicy" target="_blank">Privacy Policy</a>
      </div>
      <!--End of Subscription Footer -->    
  </div>
  <!--End of New Subscriber Setup HTML-->
</body>
</html>
