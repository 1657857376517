import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { angularMaterialModule } from "../VendorScriptFiles/AngularMaterial/angularMaterial.module";
import { AppComponent } from './app.component';
import { HomeComponent } from './Views/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AbaDatepicker } from './Scripts/CustomDirectives/ComponentDirectives/datepicker.component';
import { AbaDialog } from './Scripts/CustomDirectives/ComponentDirectives/dialog.component';
import { componentHeaderComponent } from './Scripts/layout/componentHeader.component';
import { NewSubscriberComponent } from './Scripts/User/newSubscriber.component';
import { SubscriptionComponent } from './Scripts/Subscription/Subscription.component';
import { InvoicePaymentsComponent } from './Scripts/InvoicePayments/InvoicePayments.component';
import { SubscriptionMangementService } from './Scripts/Subscription/Subscription.service';
import { InvoicePaymentsService } from './Scripts/InvoicePayments/InvoicePayments.service';
import { NewSubscriberService } from './Scripts/User/NewSubscriber.service';
import { Error404 } from './Scripts/Error/Error404.component';
import { AbaInput } from './Scripts/CustomDirectives/ComponentDirectives/abaInput.component';
import { abaLoaderComponent } from './Scripts/CustomDirectives/ComponentDirectives/abaLoader.component';
import { InvoicePDFComponent } from './Scripts/InvoicePDF/InvoicePDF.component';
import { DateConversionsComponent } from './Scripts/Global/DateConversions';
import { GlobalConfig } from './Scripts/Global/GlobalConfig';
import { abaSortDirective } from './Scripts/CustomDirectives/AttributeDirectives/abaSort.directive';
import { TermsComponent } from './AutomaticPayment/terms/terms.component';
//Declare routes here
const routes: Routes = [
    { path: '', redirectTo: '/terms', pathMatch: 'full' },
    { path: 'subscription', component: SubscriptionComponent },
    { path: 'invoicepayments', component: InvoicePaymentsComponent },
    { path: 'home', component: HomeComponent },
    { path: 'error404', component: Error404 },
    { path: 'newsubscriber', component: NewSubscriberComponent },
    { path: 'terms', component: TermsComponent }
];

@NgModule({
  declarations: [AppComponent, HomeComponent, componentHeaderComponent, NewSubscriberComponent, SubscriptionComponent, InvoicePaymentsComponent, Error404, AbaDatepicker, AbaDialog, AbaInput, abaLoaderComponent, InvoicePDFComponent, DateConversionsComponent, abaSortDirective, GlobalConfig, TermsComponent],
    imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule, FormsModule, ReactiveFormsModule, BrowserAnimationsModule, angularMaterialModule,
    RouterModule.forRoot(routes, { useHash: true })
        //[
        //{ path: '', component: SubscriptionComponent, pathMatch: 'full' },
        //{ path: 'subscription', component: SubscriptionComponent },
        //{ path: 'invoicepayments', component: InvoicePaymentsComponent },
        //{ path: 'home', component: HomeComponent },
        //{ path: 'error404', component: Error404 }
        //])
    ],
    providers: [SubscriptionMangementService, InvoicePaymentsService, NewSubscriberService, GlobalConfig],
    bootstrap: [AppComponent]
})
export class AppModule { }
