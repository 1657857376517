/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../layout/componentHeader.component.ngfactory";
import * as i2 from "../layout/componentHeader.component";
import * as i3 from "../Global/GlobalConfig";
import * as i4 from "@angular/router";
import * as i5 from "./Error404.component";
var styles_Error404 = [];
var RenderType_Error404 = i0.ɵcrt({ encapsulation: 2, styles: styles_Error404, data: {} });
export { RenderType_Error404 as RenderType_Error404 };
export function View_Error404_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "component-header", [], null, null, null, i1.View_componentHeaderComponent_0, i1.RenderType_componentHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.componentHeaderComponent, [i3.GlobalConfig, i4.ActivatedRoute], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "abaBody_Container ErrorContaier"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "notFoundContent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "notFoundData"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "abaImgIcon64"], ["icon-name", "iNoRecords-xl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "label", [["class", "abaHeader4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This page can't be viewed"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "label", [["class", "abaHeader7 abaInfoLabel"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you are trying to view can't be displayed."])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "label", [["class", "abaHeader7 abaInfoLabel"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["It may have been deleted or you don't have permission to view it right now."]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_Error404_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_Error404_0, RenderType_Error404)), i0.ɵdid(1, 49152, null, 0, i5.Error404, [], null, null)], null, null); }
var Error404NgFactory = i0.ɵccf("app-error", i5.Error404, View_Error404_Host_0, {}, {}, []);
export { Error404NgFactory as Error404NgFactory };
