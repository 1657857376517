import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '../CONSTANTS';
@Injectable()
export class InvoicePaymentsService {
  constructor(private http: HttpClient) { }

  getPendingInvoice(subscriptionId: string) {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetPendingInvoice/' + subscriptionId, { observe: 'response' });
  }
  getAllPayments(payload: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetAllPayments', payload, { observe: 'response' });
  }
  getPaymentTokenFromServer() {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetPaymentToken', { observe: 'response'});
  }
  createCustomerGateway(paymentNonce: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/CreateCustomerGateway', paymentNonce, { observe: 'response' });
  }
  chargeCustomerAmount(amountCharged: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/ChargeCustomer', amountCharged, { observe: 'response' });
  }
  getPaymentDetailsById(paymentId: number) {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetPaymentDetailsById/' + paymentId, { observe: 'response' });
  }
  getInvoiceDetailsById(invoiceId: number) {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetInvoiceDetailsById/' + invoiceId, { observe: 'response' });
  }
    getAllInvoices(subscriptionId: string) {
      return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/Subscription/Invoice/GetAllInvoices/' + subscriptionId, { observe: 'response' });
    }
    getInvoiceById(invoiceId: string) {
        return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/Subscription/Invoice/GetInvoiceById/' + invoiceId, { observe: 'response' });
    }
  getStripeKey() {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/StripeKey', { observe: 'response' });
  }
    stripeChargeCustomer(stripeChargeCustomerPayload: any) {
      //return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/StripeChargeCustomer', stripeChargeCustomerPayload, { observe: 'response' });
      return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/ChargeCustomerByStripeId', stripeChargeCustomerPayload, { observe: 'response' });
    }
    getCustomerPaymentDetails(customerId: any) {
      return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/GetCustomerPaymentDetails/' + customerId, { observe: 'response' });
    }
    createStripeCustomer(cardObject: any) {
        return this.http.post(CONSTANTS.GLOBAL_URL + "Api/Subscription/StripePayment/CreateStripeCustomer", cardObject, { observe: 'response' });
  }
  createClientSecret(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/CreatePaymentIntent', data, { observe: 'response' } );
  }
  createACHPaymentTransaction(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/CreateACHPaymentTransaction', data, { observe: 'response' });
  }
  updatePaymentIntentStatus(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/UpdatePaymentIntentStatus', data, { observe: 'response' });
  }
  attachPaymentMethodToPaymentIntent(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/AttachPaymentMethodToPaymentIntent', data, { observe: 'response' });
  }
  getAutoPayDetail(subscriptionId: string) {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/Subscription/AutoPay/GetAutoPayDetail/' + subscriptionId, { observe: 'response' });
  }
  addOrUpdateAutoPay(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/AutoPay/AddOrUpdateAutoPay', data, { observe: 'response' });
  }
  getPaymentMethodDetails(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/GetPaymentMethodDetails', data, { observe: 'response' });
  }
  updatePaymentIntentWithCustomerId(data: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/Subscription/StripePayment/updatePaymentIntentWithCustomerId', data, { observe: 'response' });
  }
}
