import { environment } from "../../environments/environment";

export class CONSTANTS {
    public static HOST_URL: string = window.location.host;
    public static GLOBAL_URL: string = environment.GLOBAL_URL;
    public static ADMIN_PORTAL_API_URL: string = environment.ADMIN_PORTAL_API_URL;
    public static SUBSCRIPTIONID: string = environment.SUBSCRIPTIONID;
    //Please Comment below line (SUBSCRIPTIONSWITCHURL) before commiting your code to BitBucket
    public static SUBSCRIPTIONSWITCHURL: string = environment.SUBSCRIPTIONSWITCHURL;
    public static REDIRECT_TO_LOGIN_URL: string = environment.LOGIN_URL;
    public static LOGOUT_URL: string = environment.LOGOUT_URL;
    public static DOMAIN: string = environment.DOMAIN;
}
