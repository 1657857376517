import { Component, ViewChild, ElementRef, Input } from '@angular/core';

declare function require(string): string;
declare var html2pdf: any;
@Component({
    selector: 'app-invoicepdf',
    templateUrl: '../../Views/InvoicePDF/InvoicePDF.component.html',
    styleUrls: ['../../Views/InvoicePDF/aba_Print.scss']
})

export class InvoicePDFComponent {
    @Input('showPaymentReciept') showPaymentReciept: boolean = false;
    @Input('pendingInvoiceResponse') pendingInvoiceResponse: any;
    @Input('invoiceFeaturesList') invoiceFeaturesList: any;
    @Input('invoiceChargesList') invoiceChargesList: any;
    @Input('paymentReceiptDetails') paymentReceiptDetails: any;

    subTotal: number = 0;
    //Converting Image to Base64
    imageToBase64Conversion(callback) {
        var img = new Image();
        var base64 = "";
        img.crossOrigin = 'Anonymous';

        // The magic begins after the image is successfully loaded
        img.onload = function () {
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);

            // Unfortunately, we cannot keep the original image type, so all images will be converted to PNG
            // For this reason, we cannot get the original Base64 string
            var uri = canvas.toDataURL('image/png'),
                b64 = uri.replace(/^data:image.+;base64,/, '');
            base64 = b64;
            if (callback) {
                callback(base64);
            }
            return base64;
        };

        // If you are loading images from a remote server, be sure to configure “Access-Control-Allow-Origin”
        var url = require('src/assets/SASS/Images/invoice-bg.png');
        img.src = url;
    }
    //end of Image Conversion
    hidePaymentreceiptDetails(showPaymentReciept: boolean) {
        this.showPaymentReciept = showPaymentReciept;
    }
    showPaymentreceiptDetails(showPaymentReciept: boolean) {
        this.showPaymentReciept = showPaymentReciept;
    }
    generateInvoice(invoiceData: any, invoiceFeatures: any, invoiceCharges) {
        this.subTotal = 0;
        this.pendingInvoiceResponse = invoiceData;
        this.invoiceFeaturesList = invoiceFeatures;
        this.invoiceChargesList = invoiceCharges;
        var options = {
            margin: [0.6, 0.5],
            filename: 'Invoice_' + this.pendingInvoiceResponse.InvoiceDetails[0].Statement_Number + '.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'in', format: "a4", orientation: 'portrait' }
        };

        var element = document.getElementById('printSectionId');
        setTimeout(() => {
            html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
                this.imageToBase64Conversion((daturl) => {
                    var pdf_pages = pdf.internal.pages;
                    var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
                    var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

                    for (var i = 1; i < pdf_pages.length; i++) {
                        // We are telling our pdfObject that we are now working on this page
                        pdf.setPage(i);
                        // The 10,200 value is only for A4 landscape. You need to define your own for other page sizes
                        pdf.addImage(daturl, 2, 4, pageWidth / 2, pageHeight / 2.2);
                        pdf.setFont("helvetica");
                        pdf.setFontSize(8);
                        pdf.setTextColor(50, 50, 50);

                        pdf.text("Make check payable to ", pageWidth / 3.15, pageHeight - 0.5, 'right');
                        pdf.setFontType("bold");
                        pdf.text("Aloha Practice Management ", pageWidth / 2.45, pageHeight - 0.5, 'center');
                        pdf.setFontType("normal");
                        pdf.text("5000 Eldorado Pkwy, STE 150-117 Frisco, TX 75033", pageWidth / 2, pageHeight - 0.5, 'left');

                        pdf.text("If you have any questions concerning this invoice, please email ", pageWidth / 1.9, pageHeight - 0.3, 'right');
                        pdf.setFontType("bold");
                        pdf.text("support@alohaaba.com. ", pageWidth / 1.64, pageHeight - 0.3, 'center');
                        pdf.setFontType("normal");
                        pdf.text("Thank You For Your Business!", pageWidth / 1.45, pageHeight - 0.3, 'left');
                    }
                    pdf.save(options.filename);
                    //window.open(pdf.output('bloburl'), '_blank');
                });                
            });
        }, 200);
    }

    paymentReceipt(paymentDetails: any, pendingInvoiceResponse: any, invoiceFeaturesList: any, invoiceChargesList: any) {
        this.paymentReceiptDetails = paymentDetails;
        this.pendingInvoiceResponse = pendingInvoiceResponse;
        this.invoiceFeaturesList = invoiceFeaturesList;
        this.invoiceChargesList = invoiceChargesList;
        var options = {
            margin: [0.6, 0.5],
            filename: 'Invoice_' + this.paymentReceiptDetails.StatementNumber + '.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'in', format: "a4", orientation: 'portrait' }
        };
        var element = document.getElementById('InvoiceReceiptPdf');
        setTimeout(() => {            
            html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
                this.imageToBase64Conversion((daturl: any) => {
                    var pdf_pages = pdf.internal.pages;
                    var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
                    var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
                    for (var i = 1; i < pdf_pages.length; i++) {
                        // We are telling our pdfObject that we are now working on this page
                        pdf.setPage(i);
                        // The 10,200 value is only for A4 landscape. You need to define your own for other page sizes
                        pdf.addImage(daturl, 2, 4, pageWidth / 2, pageHeight / 2.2);
                        pdf.setFont("helvetica");
                        pdf.setFontSize(8);
                        pdf.setTextColor(50, 50, 50);

                        if (i == pdf_pages.length - 1) {
                            pdf.text("If you have any questions concerning this invoice, please email ", pageWidth / 1.9, pageHeight - 0.5, 'right');
                            pdf.setFontType("bold");
                            pdf.text("support@alohaaba.com. ", pageWidth / 1.64, pageHeight - 0.5, 'center');
                            pdf.setFontType("normal");
                            pdf.text("Thank You For Your Business!", pageWidth / 1.45, pageHeight - 0.5, 'left');

                            pdf.setFontType("bold");
                            pdf.text("www.alohaaba.com", pageWidth / 2, pageHeight - 0.3, 'center');
                        } else {

                            pdf.text("Make check payable to ", pageWidth / 3.15, pageHeight - 0.5, 'right');
                            pdf.setFontType("bold");
                            pdf.text("Aloha Practice Management ", pageWidth / 2.45, pageHeight - 0.5, 'center');
                            pdf.setFontType("normal");
                            pdf.text("5000 Eldorado Pkwy, STE 150-117 Frisco, TX 75033", pageWidth / 2, pageHeight - 0.5, 'left');

                            pdf.text("If you have any questions concerning this invoice, please email ", pageWidth / 1.9, pageHeight - 0.3, 'right');
                            pdf.setFontType("bold");
                            pdf.text("support@alohaaba.com. ", pageWidth / 1.64, pageHeight - 0.3, 'center');
                            pdf.setFontType("normal");
                            pdf.text("Thank You For Your Business!", pageWidth / 1.45, pageHeight - 0.3, 'left');
                        }
                    }
                    pdf.save(options.filename);
                    //window.open(pdf.output('bloburl'), '_blank');
                });
            });
          }, 200);
    }
    ngOninit() {
    }
}
