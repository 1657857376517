import { Component, ViewEncapsulation } from '@angular/core';
import { SubscriptionMangementService } from './Subscription.service';
import { CONSTANTS } from '../CONSTANTS';
import { AppComponent } from '../../app.component';
import { GlobalConfig } from '../Global/GlobalConfig';

@Component({
    selector: 'app-subscription',
    templateUrl: '../../views/Subscription/Subscription.component.html',
    providers: [GlobalConfig],
    encapsulation: ViewEncapsulation.None,
})
export class SubscriptionComponent {
    subscriptionDetails: any;
    baseFeatures: any;
    baseFeaturesPrice: any;
    additionalFeatures: any;
    subscriptionId: any;
    currentMonthFeatures: any;
    baseFeature: any;
    IsModified: boolean = false;
    tempcurrentMonthFeatures: any;
    CustomerFeatures: any;
    StartDate: any;
    EndDate: any;
    Subscriptionperiod: any;
    subscriptionMangement: any;
    HasFullAccess: any;

    constructor(private SubscriptionMangementService: SubscriptionMangementService, private parent: AppComponent, private globalConfig: GlobalConfig) {
    }
    getFullSubscriptionDetails() {
        for (let i = 0; i < this.additionalFeatures.length; i++) {
            if (this.currentMonthFeatures && this.currentMonthFeatures.CustomerFeatures) {
                for (let j = 0; j < this.currentMonthFeatures.CustomerFeatures.length; j++) {
                    if (this.additionalFeatures[i].Feature_Clv == this.currentMonthFeatures.CustomerFeatures[j].Feature_Clv) {
                        this.additionalFeatures[i].IsSubscribed = this.currentMonthFeatures.CustomerFeatures[j].IsEnabled;
                        this.additionalFeatures[i].CustomerFeaturePrice = this.currentMonthFeatures.CustomerFeatures[j].CustomerFeaturePrice;
                    }
                    if (this.currentMonthFeatures.CustomerFeatures[j].IsBasePrice) {
                        this.baseFeature = true;
                        this.baseFeaturesPrice = this.currentMonthFeatures.CustomerFeatures[j].CustomerFeaturePrice;
                    }
                }
            }
        }
    }
    manageSubscribedFeatures(additionalFeature, value) {
        this.IsModified = true;
    }
    getUnModifiedDetails() {
        this.additionalFeatures.forEach(x => x.IsSubscribed = false);
        this.getFullSubscriptionDetails();
        this.IsModified = false;
    }
    manageFeatures() {
        this.parent.currentPageLoading = true;
        if (this.additionalFeatures) {
            for (var i = 0; i < this.additionalFeatures.length; i++) {
                if (this.additionalFeatures[i].IsSubscribed) {
                    this.CustomerFeatures.push(this.additionalFeatures[i]);
                }
            }
        }
        if (this.currentMonthFeatures && this.currentMonthFeatures.CustomerFeatures) {
            for (var k = 0; k < this.currentMonthFeatures.CustomerFeatures.length; k++) {
                if (this.currentMonthFeatures.CustomerFeatures[k].IsBasePrice) {
                    this.CustomerFeatures.push(this.currentMonthFeatures.CustomerFeatures[k]);
                }
            }
            for (let i = 0; i < this.CustomerFeatures.length; i++) {
                this.CustomerFeatures[i].CustomerFeature_Id = this.CustomerFeatures[i].CustomerFeature_Id || -1;
                for (let j = 0; j < this.additionalFeatures.length; j++) {
                    if (this.additionalFeatures[j].Feature_Clv == this.CustomerFeatures[i].Feature_Clv) {
                        this.CustomerFeatures[i].Feature_Id = this.additionalFeatures[j].FeatureId;
                    }
                }

                for (let j = 0; j < this.currentMonthFeatures.CustomerFeatures.length; j++) {
                    if (this.currentMonthFeatures.CustomerFeatures[j].Feature_Clv == this.CustomerFeatures[i].Feature_Clv) {
                        this.CustomerFeatures[i].CustomerFeature_Id = this.currentMonthFeatures.CustomerFeatures[j].CustomerFeature_Id || -1;
                    }
                }
            }
        }
        this.IsModified = false;
        this.subscriptionMangement.CustomerFeatures = this.CustomerFeatures;
        this.SubscriptionMangementService.manageSubscribedFeatures(this.subscriptionMangement)
            .subscribe(data => {
                this.ngOnInit();
                console.log("SUCCESS......", data.body);
            }, err => {
                    console.log("Log Error......", err.error.Message)
                    this.parent.currentPageLoading = false;
            });
        this.CustomerFeatures = [];
    }

    convertDate(date: any) {
        if (typeof (date) == 'number') {
            var unixDate = date * 1000;
            return new Date(unixDate).toISOString().substring(0, 10);
        } else {
            var localDate = new Date(date);
            return (localDate.getTime() + Math.abs(localDate.getUTCSeconds() * 60000)) / 1000;
        }
    }

    ngOnInit() {
        this.CustomerFeatures = [];
        this.subscriptionMangement = {};
        this.baseFeature = false;
        this.HasFullAccess = false;
        if (this.globalConfig.getCookie("subscriptionPortalAccess") == 'True') {
            this.HasFullAccess = true;
            this.SubscriptionMangementService.getSubscriptionDetails()
                .subscribe(data => {
                    this.subscriptionDetails = data.body;
                    this.baseFeatures = this.subscriptionDetails.BaseFeatures;
                    this.baseFeaturesPrice = this.subscriptionDetails.BasePlanPrice;
                    this.additionalFeatures = this.subscriptionDetails.AdditionalFeatures;
                    this.additionalFeatures.forEach(x => x.IsSubscribed = false);
                    this.subscriptionId = this.globalConfig.getCookie('SubscricptionId');
                    this.SubscriptionMangementService.getCurrentMonthFeatures(this.subscriptionId)
                        .subscribe(data => {
                            this.tempcurrentMonthFeatures = data.body;
                            this.subscriptionMangement.CustomerPlan_Id = -1;
                            this.subscriptionMangement.invoice_Id = -1;
                            if (this.tempcurrentMonthFeatures.length > 0) {
                                this.currentMonthFeatures = this.tempcurrentMonthFeatures[0];
                                //this.StartDate = new Date(this.currentMonthFeatures.StartDate * 1000);
                                //this.EndDate = new Date(this.currentMonthFeatures.EndDate * 1000);
                                this.StartDate = this.convertDate(this.currentMonthFeatures.StartDate);
                                this.EndDate = this.convertDate(this.currentMonthFeatures.EndDate);
                                this.Subscriptionperiod = this.currentMonthFeatures.Subscriptionperiod;
                                this.subscriptionMangement.CustomerPlan_Id = this.currentMonthFeatures.CustomerPlan_Id || -1;
                                this.subscriptionMangement.invoice_Id = this.currentMonthFeatures.invoice_Id || -1;
                                this.getFullSubscriptionDetails();
                            }
                            this.parent.currentPageLoading = false;
                        }, err => {
                            console.log("Log Error......", err.error.Message);
                            this.parent.currentPageLoading = false;
                        }
                        );
                }, err => {
                    console.log("Log Error......", err.error.Message);
                    this.parent.currentPageLoading = false;
                });
        }
    }
}
