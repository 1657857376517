import { Component, TemplateRef } from '@angular/core';
import { InvoicePaymentsService } from './InvoicePayments.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { CONSTANTS } from '../CONSTANTS';
import { AppComponent } from '../../app.component';
import { InvoicePDFComponent } from '../InvoicePDF/InvoicePDF.component';
import { DateConversionsComponent } from '../Global/DateConversions';
import { GlobalConfig } from '../Global/GlobalConfig';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
declare var braintree: any;
declare var Stripe: any;

@Component({
    selector: 'app-invoicepayments',
    templateUrl: '../../views/InvoicePayments/InvoicePayments.component.html',
    providers: [InvoicePDFComponent, DateConversionsComponent, GlobalConfig]
})
export class InvoicePaymentsComponent {
    invoiceFeaturesList: any;
    invoiceChargesList: any;
    additionalChargesTotal: number = 0;
    totalInvoiceDiscount: any;
    invoiceTotalAmount: number = 0;
    paymentReceiptdetails: any;
    sixMonthPreviousDate: Date = new Date();
    endDateForPaymentHistoryFilter: Date = new Date(new Date().getFullYear(), (new Date().getMonth()) + 1, 0, 23, 59, 59);
    paymentHistoryDateDetails: any = {
        "StartDatePlaceholder": "Start Date",
        "StartDateMinDate": "",
        "StartDateMaxDate": new Date(),
        "StartDateModel": "",
        "EndDatePlaceholder": "End Date",
        "EndDateMinDate": "",
        "EndDateMaxDate": this.endDateForPaymentHistoryFilter,
        "EndDateModel": this.endDateForPaymentHistoryFilter
    };
    pendingInvoiceResponse: any = {};
    pendingInvoiceDetails: any = [];
    pendingInvoiceDetailsCopy: any = [];
    showPaymentReciept: boolean = false;
    paymentStatusClv: any = {
        "PYMNTOVERDUE_PMNTMSG": {
            "name": "Payment Overdue",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Payment Overdue!",
            "message": "You have charge(s) past overdue. Please pay the full amount to avoid suspension or termination of your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "PYMNTDUE_PMNTMSG": {
            "name": "Payment Due",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Payment Due!",
            "message": "You have charge(s) past due. Please pay the full amount to continue uninterrupted access to your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "PAST_DUE": {
            "name": "Past Payment Due",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Past Payment Due!",
          "message": "You have a charge that is past due. Please pay the full amount to continue uninterrupted access to your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "DUE": {
            "name": "Payment Due",
            "color": "abaSemiGray",
            "borderColor": "abaSemiGrayBrdr",
            "title": "Payment Due!",
          "message": "Your current invoice is ready!   If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        }
    };
    paymentModeTypes: any = [{
        type: "visa",
        name: "Visa"
    }, {
        type: "paypal",
        name: "Paypal"
    }];
    paymentMode: any = this.paymentModeTypes[0];
    paymentModeType: any = this.paymentModeTypes[0];
    BRAINTREE_JWT_TOKEN: any = "";
    paymentConfirmationDetails: any = {};
    paymentInstance: any = {};
    paymentErrorOccurred: boolean = false;
    paymentInfoOccurred: boolean = false;
    paymentSuccessOccurred: boolean = false;
    isCardPayment: boolean = true;
    showPendingInvoices: boolean = true;
    stripeObject: any = {};
    StripePublishableKey: any = "";
    alertMessageDetails: any = {};
    paymentHistoryArray: any = [];
    noRecordsAvailable: boolean;
    dialogReferences: any = {};
    invoiceResponse: any = {};
    hidePdf: boolean = true;
    invoiceDate: any = '';
    invoiceMonth: any = '';
    invoiceDueDate: any = '';
    customerCardDetails: any = [];
    CustomerPaymentKeyDetails: any = [];
    selectedPendingInvoice: any = {};
    staticData: any = {
      paymentLoadingGifName: "aba_Loader.gif",    
      alohaLoadingGifName: "aloha_Loader.gif",    
      paymentLoading: "Please wait while we process your payment."
    };
    subscriptionEmail: String = this.globalConfig.getCookie('UserName');
    ClientSecretKey: any = {};
    globalSubmitData: any = {};
    globalPaymentElement: any = {};
    globalStripeElement: any = {};
    MicrodepositLink: string = '';
    PaymentMethodsDataList: any = [];
    isErrorOccured: string = "";
    isCardSelected: boolean = true;
    automaticPayment: any = {
      "IsAutoDebit": true,
      "RMD_Mail": true,
      "CNF_PYMT_Mail": false,
      "CNF_Config_Mail": false,
      "Rej_Mail": false,
      "Autopay_Id": -1
    };
    autoPaymentMode: any = {};
    paymentMethodLast4: any = null;
    backupForAutomaticPayment: any = {};
    autoPaymentStatusInfo:any = {};
    showAutoPayLabel:boolean = false;
    showValidationMessage: boolean = false;
    validatedMessage: string = "";
  isCurrentMonthInvoicePaid: boolean = false;
  AutoPayStatusLabel: string = "";
  constructor(private InvoicePaymentsService: InvoicePaymentsService, private dateConversions: DateConversionsComponent, private dialog: MatDialog, public invoicepdf: InvoicePDFComponent, private parent: AppComponent, private globalConfig: GlobalConfig, private router: Router) {
    }
    changeDate() {
        console.log("Changed Dte::::");
    }
    openPaymentPopup(templateRef: TemplateRef<any>, pendingInvoiceItem: any) {
      this.parent.currentPageLoading = true;
      this.parent.gif = this.staticData.alohaLoadingGifName;
      this.dialogReferences.paymentDialog = templateRef;
        this.dialogReferences.paymentDialogHandler = this.dialog.open(templateRef);
        this.selectedPendingInvoice = pendingInvoiceItem;
      //document.getElementById("paymentOptionsContainer").innerHTML = '';
        this.paymentMode = this.paymentModeTypes[0];
      this.changePaymentType(this.paymentMode, '');
    }
    openPaymentStatusDialog(templateRef: TemplateRef<any>) {
        this.dialogReferences.paymentSummaryDialog = templateRef;
        this.dialogReferences.paymentSummaryDialogHandler = this.dialog.open(templateRef);
    }

  // Generating Invoice PDF
  customiseInvoice(pendingResponse: any) {
      this.pendingInvoiceResponse = pendingResponse;
      this.invoiceFeaturesList = this.pendingInvoiceResponse.InvoiceItems;
      this.additionalChargesTotal = 0;
      if (this.pendingInvoiceResponse.InvoiceDetails && this.pendingInvoiceResponse.InvoiceAdditionalCharges) {
          if (!this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount) {
              this.totalInvoiceDiscount = "Nil";
          }
          else {
              if (this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Discount_Name == '$') {
                  this.totalInvoiceDiscount = "$" + this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount;
              }
              else {
                  this.totalInvoiceDiscount = this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount + "%";
              }
          }
          if (this.pendingInvoiceResponse.InvoiceAdditionalCharges.length > 0) {
              for (var a = 0; a < this.pendingInvoiceResponse.InvoiceAdditionalCharges.length; a++) {
                  this.additionalChargesTotal = this.additionalChargesTotal + this.pendingInvoiceResponse.InvoiceAdditionalCharges[a].Amount;
              }
          }
          this.invoiceDate = this.dateConversions.convertDateToMMMDDYYYY(this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Generated_Date);
          this.invoiceDueDate = this.dateConversions.convertDateToMMMDDYYYY(this.pendingInvoiceResponse.InvoiceDetails[0].DueDate);
          this.invoiceMonth = this.dateConversions.convertDateToMMMM_YYYY(this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Generated_Date);
          this.invoiceChargesList = {
              totalAmount: this.pendingInvoiceResponse.InvoiceDetails[0].SubTotal,
              discountAmount: this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount,
              totalInvoiceDiscount: this.totalInvoiceDiscount,
              additionalChargesTotal: this.additionalChargesTotal,
              totalAmountBilled: this.pendingInvoiceResponse.InvoiceDetails[0].AmountBilled,
              invoiceDate: this.invoiceDate,
              invoiceMonth: this.invoiceMonth,
              invoiceDueDate: this.invoiceDueDate
          }
          let totalResponse = {
              pendingInvoiceResponse: this.pendingInvoiceResponse,
              invoiceFeaturesList: this.invoiceFeaturesList,
              invoiceChargesList: this.invoiceChargesList
          }
          return totalResponse;
      }
      return null;
    }

  generateInvoice() {
      this.invoicepdf.generateInvoice(this.invoiceResponse.pendingInvoiceResponse, this.invoiceResponse.invoiceFeaturesList, this.invoiceResponse.invoiceChargesList);
  }
    paymentReceipt(paymentId: any, invoiceId: any) {
        this.invoicepdf.paymentReceipt(this.paymentReceiptdetails, this.invoiceResponse.pendingInvoiceResponse, this.invoiceResponse.invoiceFeaturesList, this.invoiceResponse.invoiceChargesList);
    }
    //Invoice Preview code by Anil
    openPendinginvoice(templateRef: TemplateRef<any>, invoiceId: any) {
        //this.InvoicePaymentsService.getPendingInvoice(CONSTANTS.SUBSCRIPTIONID)
        this.InvoicePaymentsService.getInvoiceById(invoiceId)
            .subscribe(response => {
                if (response && response.body) {
                    this.invoicepdf.hidePaymentreceiptDetails(this.showPaymentReciept);                   
                    this.pendingInvoiceResponse = response.body;
                    this.invoiceResponse = this.customiseInvoice(this.pendingInvoiceResponse);
                    this.dialog.open(templateRef);
                }
            }, error => { });
    }
    openHistoryinvoice(paymentId: any, invoiceId: any,templateRef: TemplateRef<any>) {
        this.InvoicePaymentsService.getPaymentDetailsById(paymentId).subscribe(response => {
            if (response && response.body) {
                this.paymentReceiptdetails = response.body;
                //this.InvoicePaymentsService.getInvoiceDetailsById(invoiceId).subscribe(response => {
                this.InvoicePaymentsService.getInvoiceById(invoiceId).subscribe(response => {
                    if (response && response.body) {
                        this.pendingInvoiceResponse = [];
                        this.pendingInvoiceResponse = response.body;
                        this.invoicepdf.showPaymentreceiptDetails(this.showPaymentReciept);
                        this.paymentReceiptdetails.EmailId = this.subscriptionEmail;
                        this.paymentReceiptdetails.TotalAmount = parseFloat(this.paymentReceiptdetails.TotalAmount).toFixed(2);
                        this.paymentReceiptdetails.PaymentDate = this.dateConversions.convertDateToMMMMDDYYYY(this.paymentReceiptdetails.PaymentDateTime);
                        this.paymentReceiptdetails.PaymentTime = this.dateConversions.convertDateToHMMSS(this.paymentReceiptdetails.PaymentDateTime);
                        this.invoiceResponse = this.customiseInvoice(this.pendingInvoiceResponse);
                        this.invoiceFeaturesList = this.invoiceResponse.invoiceFeaturesList;
                        this.invoiceChargesList = this.invoiceResponse.invoiceChargesList;
                        this.dialog.open(templateRef);
                    }
                },
                    error => { });
            }
        },
            error => { }); 
    }   
    //end of Invoice Preview 

    filterInvoices() {
      this.pendingInvoiceDetails = this.pendingInvoiceDetailsCopy.filter(item => {
          return this.showPendingInvoices ? item.IsPending : true;
      });
    }
    
    // On selecting payment mode type
  changePaymentType(paymentModeTypeItem: any, event: any) {
    let self = this;
    self.paymentErrorOccurred = false;
    self.paymentSuccessOccurred = false;
    self.paymentInfoOccurred = false;
        if (paymentModeTypeItem.type == "visa") {
            this.isCardPayment = true;
            //if (!event && (!this.StripePublishableKey || !this.stripeObject || !this.stripeObject.card)) {
            //  //this.stripePayment();

            //}
            //else {
            //    setTimeout(() => {
            //        //this.initialiseStripe(this.StripePublishableKey);
            //    }, 300);
          //}
          self.InvoicePaymentsService.getStripeKey().subscribe(response => {
            let responseObject = JSON.parse(JSON.stringify(response));
            if (responseObject && responseObject.body) {
              self.StripePublishableKey = responseObject.body.PublishableKey || self.StripePublishableKey;
              if (self.selectedPendingInvoice && self.selectedPendingInvoice.ClientSecret) {
                self.parent.loaderMessage = this.staticData.paymentLoading;
                self.parent.gif = this.staticData.paymentLoadingGifName;
                self.parent.currentPageLoading = true;
                const stripe = Stripe(self.StripePublishableKey);
                stripe.retrievePaymentIntent(self.selectedPendingInvoice.ClientSecret).then(function (result) {
                  console.log("Retrieve_PAYMENT_INTENT:::::", result);
                  if (result.error) {
                    self.dialogReferences.paymentDialogHandler.close();
                    self.parent.currentPageLoading = false;
                    setTimeout(() => {
                      self.dialogReferences.paymentSummaryDialogHandler.close();
                    }, 2000);
                  }
                  if (result.paymentIntent.status == "processing") {
                    self.paymentInfoOccurred = true;
                    self.dialogReferences.paymentDialogHandler.close();
                    self.parent.currentPageLoading = false;
                    document.getElementById("openPaymentStatusPopup").click();
                    setTimeout(() => {
                      self.dialogReferences.paymentSummaryDialogHandler.close();
                    }, 5000);
                  } else if (result.paymentIntent.status == "requires_payment_method") {
                    self.globalSubmitData = {
                      CustomerId: self.globalConfig.getCookie('UserName'),
                      Amount: parseFloat(self.selectedPendingInvoice.AmountBilled),
                      InvoiceId: self.selectedPendingInvoice.Invoice_Id,
                      SubscriptionId: self.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID
                    }
                    self.ClientSecretKey = result.paymentIntent;
                    self.ClientSecretKey.TransactionId = result.paymentIntent.id;
                    self.initialiseStripe2(self.StripePublishableKey, "#stripPaymentContainer", response.body);
                  } else if (result.paymentIntent.status == "requires_action") {
                    if (result.paymentIntent.next_action.type == "verify_with_microdeposits") {
                      self.MicrodepositLink = result.paymentIntent.next_action.verify_with_microdeposits.hosted_verification_url;
                      self.dialogReferences.paymentDialogHandler.close();
                      self.parent.currentPageLoading = false;
                      let newWin = window.open(self.MicrodepositLink, 'popup', 'width=600,height=700,scrollbars=yes,resizable=yes,toolbar=no,directories=no,location=no,menubar=no,status=no,left=50,top=0');
                      let timer = setInterval(function () {
                        if (newWin.closed) {
                          clearInterval(timer);
                          location.reload();
                        }
                      }, 1000);
                      //window.open(self.MicrodepositLink, "openPaymentStatusPopup");
                      //document.getElementById("openPaymentStatusPopup").click();
                    }
                  } else {
                    self.dialogReferences.paymentDialogHandler.close();
                    self.parent.currentPageLoading = false;
                  }
                });
              } else {
                self.stripePayment2();
              }
            }
          }, err => {
            console.log("ERROR IN getStripeKey:::", err);
          });
        } else if (paymentModeTypeItem.type == "card" && paymentModeTypeItem.StripeCustomerId) {
            //
        }
        //else {
        //    this.isCardPayment = false;
        //    //document.getElementById("paymentOptionsContainer").innerHTML = '';
        //}
      this.paymentMode = paymentModeTypeItem;
    }

    stripePayment() {
      try {
        
            this.InvoicePaymentsService.getStripeKey().subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.body) {
                    this.StripePublishableKey = responseObject.body.PublishableKey || this.StripePublishableKey;
                    //this.initialiseStripe(this.StripePublishableKey);
                    let paymentItem: any = {};
                  this.customerCardDetails = [];

                    for (let i = 0; i < this.CustomerPaymentKeyDetails.length; i++) {
                        paymentItem = this.CustomerPaymentKeyDetails[i];
                        this.stripeObject.stripe.retrieveSource({
                            id: paymentItem.SourceId,
                            client_secret: paymentItem.ClientSecret,
                        }).then((result: any) => {
                            if (result) {
                              result.source.StripeCustomerId = paymentItem.StripeCustomerId;
                           
                              if (this.customerCardDetails.length == 0) {
                                this.customerCardDetails.push(result.source);
                              }
                              else
                              {
                                let cardsAreEqual = false;
                                for (let j = 0; j < this.customerCardDetails.length; j++)
                                {
                                  cardsAreEqual = this.areCardsEqual(this.customerCardDetails[j], result.source);
                                  if (cardsAreEqual) { break;}
                                }
                                if (!cardsAreEqual) {  
                                  this.customerCardDetails.push(result.source);
                                }
                              }
                            }
                        });
                  }
                
                }
            }, err => {
                console.log("ERROR IN getStripeKey:::", err);
            });
     

        } catch (e) {
            console.log("ERROR in stripePayment:::::::::", e);
        }
  }

  areCardsEqual(card1: any, card2: any): boolean { return card1.card.brand === card2.card.brand && card1.card.last4 === card2.card.last4; }

 
  stripePayment2() {
    
    try {
      let self = this;
      this.InvoicePaymentsService.getStripeKey().subscribe(response => {
        let responseObject = JSON.parse(JSON.stringify(response));
        if (responseObject && responseObject.body) {
          this.StripePublishableKey = responseObject.body.PublishableKey || this.StripePublishableKey;
          //this.initialiseStripe(this.StripePublishableKey);

          setTimeout(function () {

            self.globalSubmitData = {
              CustomerId: self.globalConfig.getCookie('UserName'),
              Amount: parseFloat(self.selectedPendingInvoice.AmountBilled),
              InvoiceId: self.selectedPendingInvoice.Invoice_Id,
              SubscriptionId: self.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID
            }
            self.InvoicePaymentsService.createClientSecret(self.globalSubmitData).subscribe(response => {
              console.log(response)
              self.ClientSecretKey = response.body;
              self.selectedPendingInvoice.ClientSecret = self.ClientSecretKey.StripeClientSecret;
              self.initialiseStripe2(self.StripePublishableKey, "#stripPaymentContainer", response.body);
              let paymentItem: any = {};
              //self.customerCardDetails = [];

              for (let i = 0; i < self.CustomerPaymentKeyDetails.length; i++) {
                paymentItem = self.CustomerPaymentKeyDetails[i];
                if (paymentItem.SourceId) {
                  self.stripeObject.stripe.retrieveSource({
                    id: paymentItem.SourceId,
                    client_secret: paymentItem.ClientSecret,
                  }).then((result: any) => {
                    if (result) {
                      result.source.StripeCustomerId = paymentItem.StripeCustomerId;

                      if (self.customerCardDetails.length == 0) {
                        self.customerCardDetails.push(result.source);
                      }
                      else {
                        let cardsAreEqual = false;
                        for (let j = 0; j < self.customerCardDetails.length; j++) {
                          cardsAreEqual = self.uniqueList(self.customerCardDetails[j], result.source);
                          if (cardsAreEqual) { break; }
                        }
                        if (!cardsAreEqual) {
                          self.customerCardDetails.push(result.source);
                        }
                      }
                      self.customerCardDetails = self.customerCardDetails.length > 0 ? self.filteredCardDetails(self.customerCardDetails) : self.customerCardDetails;
                    }
                  });
                }
              }
              console.log('self.customerCardDetails::', self.customerCardDetails);
            }, err => {
              console.log("ERROR IN createClientSecret:::", err);
            });
          }, 300)
          

        }
      }, err => {
        console.log("ERROR IN getStripeKey:::", err);
      });


    } catch (e) {
      console.log("ERROR in stripePayment:::::::::", e);
    }
  }
 


    initialiseStripe(stripeKey: any) {
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#32325d',
            }
        };
      var stripe = Stripe(stripeKey);
      const paymentElementOptions = {
        layout: "tabs",
        paymentMethodOrder: ['card', 'us_bank_account']
      };
        var elements = stripe.elements();
        // Create an instance of the card Element.
      // var card = elements.create('card', { style: style });
      const paymentElement = elements.create("payment", paymentElementOptions);

        // Add an instance of the card Element into the `stripeCardElement` <div>.
      //card.mount('#stripeCardElement');
      paymentElement.mount('#stripeCardElement');
        this.stripeObject.stripe = stripe;
      this.stripeObject.card = paymentElement;
        this.parent.currentPageLoading = false;
  }

  initialiseStripe2(stripeKey: any, id: any, response: any) {
    let self = this;
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        color: '#32325d',
      }
    };
    const stripe = Stripe(stripeKey);
    const elements = stripe.elements({
      clientSecret: response.StripeClientSecret || self.ClientSecretKey.client_secret,
    });
    const paymentElementOptions = {
      layout: "tabs",
      paymentMethodOrder: ['card', 'us_bank_account']
    };
    // Create an instance of the card Element.
    // var card = elements.create('card', { style: style });
    const paymentElement = elements.create("payment");
    // Add an instance of the card Element into the `stripeCardElement` <div>.
    paymentElement.mount('#stripeCardElement');
    this.globalStripeElement = stripe;
    this.globalPaymentElement = elements;
      this.stripeObject.stripe = stripe;
      this.stripeObject.card = paymentElement;
    this.parent.currentPageLoading = false;
    this.customerCardDetails = this.uniqueList(this.customerCardDetails, true);
    this.customerCardDetails = this.customerCardDetails.length > 0 ? this.filteredCardDetails(this.customerCardDetails) : this.customerCardDetails;
    paymentElement.on('change', function (event) {
      console.log(event);
      self.globalSubmitData.PaymentMethod = event.value.type;
    });
  }

  attachPaymentMethodToIntent(payment: any) {
    const stripe = Stripe(this.StripePublishableKey);
    let paymentIntent = {
      PaymentIntentId: this.selectedPendingInvoice.PaymentTransactionId || this.ClientSecretKey.TransactionId,
      PaymentMethodId: payment.id,
      Invoice_Id: this.selectedPendingInvoice.Invoice_Id
    }
    this.InvoicePaymentsService.attachPaymentMethodToPaymentIntent(paymentIntent).subscribe(response => {
      console.log(response);
    });
  }

  createACHPaymentTransaction() {
    let self = this;
    var submitData = {
      PaymentMethod: self.globalSubmitData.PaymentMethod,
      TransactionId: self.ClientSecretKey.TransactionId,
      PaymentInstrument: "",
      AmountPaid: self.globalSubmitData.Amount,
      InvoiceId: self.globalSubmitData.InvoiceId,
      PaymentStatus: self.globalSubmitData.PaymentStatus,
      PaymentIntentId: self.globalSubmitData.PaymentIntentId,
      PaymentMethodId: self.globalSubmitData.PaymentMethodId,
      StripeCustomerId: self.globalSubmitData.StripeCustomerId
    }
    this.InvoicePaymentsService.createACHPaymentTransaction(submitData).subscribe(response => {
      if (response && response.body) {
        self.getInvoices();
      } 
    }, err => {
      console.log("ERROR IN createACHPaymentTransaction:::", err);
    });
  }

  createClientSecret(amount) {
    var submitData = {
      CustomerId: this.globalConfig.getCookie('UserName'),
      Amount: amount,
      SubscricptionId: this.globalConfig.getCookie('SubscricptionId')
    }
    this.InvoicePaymentsService.createClientSecret(submitData).subscribe(response => {
      console.log(response)
    }, err => {
      console.log("ERROR IN createClientSecret:::", err);
    });
  }
  //createClientSecret(5);

    submitPayment() {
        this.parent.loaderMessage = this.staticData.paymentLoading;
        this.parent.gif = this.staticData.paymentLoadingGifName;
        this.parent.currentPageLoading = true;
        //// Create a token or display an error when the form is submitted.
        if (this.paymentMode && this.paymentMode.type == "visa") {
            this.stripeObject.stripe.createToken(this.stripeObject.card).then((result: any) => {
                if (result.error) {
                    // Inform the customer that there was an error.
                    var errorElement = document.getElementById('stripeCardErrors');
                    errorElement.textContent = result.error.message;
                    this.parent.currentPageLoading = false;
                } else {
                    if (result && result.token && result.token.id) {
                        this.stripeObject.stripe.createSource(this.stripeObject.card, {
                            type: 'card'
                        }).then((result1) => {
                            if (result1) {
                                this.stripeObject.source_id = result1.source.id;
                                this.stripeObject.client_secret = result1.source.client_secret;
                                this.createStripeCustomer(result.token);
                            }
                        });
                    }
                }
            });
        } else if (this.paymentMode && this.paymentMode.type == "card" && this.paymentMode.StripeCustomerId){
            this.makePayment(this.paymentMode.StripeCustomerId);
        }
  }

  submitPayment2() {
    let self = this;
    self.parent.loaderMessage = this.staticData.paymentLoading;
    self.parent.gif = this.staticData.paymentLoadingGifName;
    self.parent.currentPageLoading = true;
    const stripe = self.globalStripeElement;

    const elements = this.globalPaymentElement;
    try {
      if (self.paymentMode && (self.paymentMode.card || self.paymentMode.us_bank_account)) {
        let paymentIntent = {
          PaymentIntentId: this.selectedPendingInvoice.PaymentTransactionId || this.ClientSecretKey.TransactionId,
          PaymentMethodId: self.paymentMode.id,
          Invoice_Id: this.selectedPendingInvoice.Invoice_Id,
          CustomerId: this.paymentMode.customer,
          Amount: parseFloat(self.selectedPendingInvoice.AmountBilled)
        }
        this.InvoicePaymentsService.attachPaymentMethodToPaymentIntent(paymentIntent).subscribe(response => {
          console.log(response);
          if (response.body) {
            let result: any = response.body;
            result = JSON.parse(result);
            console.log("PaymentIntent:::: ", result);
            self.globalSubmitData.PaymentStatus = result.status;
            self.globalSubmitData.PaymentIntentId = result.id;
            self.globalSubmitData.PaymentMethodId = result.payment_method;
            self.ClientSecretKey.TransactionId = result.id;
            self.globalSubmitData.StripeCustomerId = result.customer;
            self.paymentSuccessOccurred = true;
            self.dialogReferences.paymentDialogHandler.close();
            self.parent.currentPageLoading = false;
            document.getElementById("openPaymentStatusPopup").click();
            setTimeout(() => {
              self.dialogReferences.paymentSummaryDialogHandler.close();
              self.createACHPaymentTransaction();
            }, 2000);
          } else {
            self.paymentErrorOccurred = true;
            self.parent.currentPageLoading = false;
          }
        }, error => {
          self.isErrorOccured = error.error.Message;
          self.dialogReferences.paymentDialogHandler.close();
          self.parent.currentPageLoading = false;
          document.getElementById("openPaymentStatusPopup").click();
          setTimeout(() => {
            self.dialogReferences.paymentSummaryDialogHandler.close();
          }, 2000);
          console.log("ERROR in attachPaymentMethodToPaymentIntent::::", error);
        });
      } else {
        let data = {
          InvoiceId: this.selectedPendingInvoice.Invoice_Id,
          PaymentIntentId: this.selectedPendingInvoice.PaymentTransactionId || this.ClientSecretKey.TransactionId
        }
        self.InvoicePaymentsService.updatePaymentIntentWithCustomerId(data).subscribe(response => {
          stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.href,
              //payment_method_data: {
              //  billing_details: {
              //    name: 'Jenny Rosen',
              //    email: 'jenny.rosen@example.com',
              //  }
              //},
            },
            redirect: 'if_required'
          }).then(function (result) {
            if (result.error) {
              self.paymentErrorOccurred = true;
              self.parent.currentPageLoading = false;
            } else {
              console.log("PaymentIntent:::: ", result);
              self.globalSubmitData.PaymentStatus = result.paymentIntent.status;
              self.globalSubmitData.PaymentIntentId = result.paymentIntent.id;
              self.globalSubmitData.PaymentMethodId = result.paymentIntent.payment_method;
              self.globalSubmitData.StripeCustomerId = result.paymentIntent.customer;
              self.paymentSuccessOccurred = true;
              self.dialogReferences.paymentDialogHandler.close();
              self.parent.currentPageLoading = false;
              document.getElementById("openPaymentStatusPopup").click();
              setTimeout(() => {
                self.dialogReferences.paymentSummaryDialogHandler.close();
                self.createACHPaymentTransaction();
              }, 2000);
            }
          });
        }, err => {
          console.log("ERROR IN updatePaymentIntentWithCustomerId:::", err);
        });
      }
      
    } catch (e) {
      console.log("error", e);
    }

  }

    createStripeCustomer(token) {
        // Submit token ID to the server
        if (token && token.id) {
            var userEmail = this.globalConfig.getCookie('UserName');
            var submitCardToken = {
                "EmailId": userEmail,
                "CardToken": token.id,
                "SubscriptionId": CONSTANTS.SUBSCRIPTIONID,
                "ClientSecret": this.stripeObject.client_secret,
                "SourceId": this.stripeObject.source_id
            };
            this.InvoicePaymentsService.createStripeCustomer(submitCardToken).subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.status == 200 && responseObject.body) {
                    if (responseObject.body.StripeCustomerId) {
                        this.makePayment(responseObject.body.StripeCustomerId);
                    }
                }
            }, error => {
                console.log("ERROR in createStripeCustomer::::", error);
            });
        }
    }

    makePayment(StripeCustomerId: any) {
        if (StripeCustomerId) {
            var submitData = {
                "InvoiceId": this.selectedPendingInvoice.Invoice_Id,
                "StripeCustomerId": StripeCustomerId,
                "Amount": this.selectedPendingInvoice.AmountBilled
            };
            this.InvoicePaymentsService.stripeChargeCustomer(submitData).subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.body && responseObject.body.StatusCode == 200) {
                  this.paymentSuccessOccurred = true;
                    this.dialogReferences.paymentDialogHandler.close();
                    this.parent.currentPageLoading = false;
                    document.getElementById("openPaymentStatusPopup").click();
                    this.getInvoices();
                    //this.pendingInvoiceDetails = [];
                    //this.selectedPendingInvoice = {};
                    //this.alertMessageDetails = {};
                    //this.loadAllPayments("duringPayment");
                    setTimeout(() => {
                        this.dialogReferences.paymentSummaryDialogHandler.close();
                    }, 5000);
                } else {
                    this.paymentErrorOccurred = true;
                    this.dialogReferences.paymentDialogHandler.close();
                    this.parent.currentPageLoading = false;
                    document.getElementById("openPaymentStatusPopup").click();
                }
            }, err => {
                console.log("ERROR IN StripeChargeCustomer:::", err);
                this.paymentErrorOccurred = true;
                this.dialogReferences.paymentDialogHandler.close();
                this.parent.currentPageLoading = false;
                document.getElementById("openPaymentStatusPopup").click();
            });
        }
    }

    uniqueList(list:any, isCard:boolean) {
      let uniqueItems: any = [];
      if (list && list.length > 0) {
        if (isCard) {
          uniqueItems = list.filter((item, index, self) =>
            index === self.findIndex((t) => ((t.card.brand).toLowerCase() === (item.card.brand).toLowerCase()) && t.card.last4 === item.card.last4)
          );
        } else {
          uniqueItems = list.filter((item, index, self) =>
            index === self.findIndex((t) => t.us_bank_account.fingerprint === item.us_bank_account.fingerprint && t.us_bank_account.last4 === item.us_bank_account.last4)
          );
        }
        
      }
      return uniqueItems;
    }

    getCustomerCardDetails(customerId: any) {
      let self = this;
      self.parent.gif = this.staticData.alohaLoadingGifName;
      self.parent.currentPageLoading = true;
      self.InvoicePaymentsService.getCustomerPaymentDetails(customerId).subscribe(response => {
        let responseObject = JSON.parse(JSON.stringify(response));
        if (responseObject && responseObject.body && responseObject.status == 200) {
          self.CustomerPaymentKeyDetails = responseObject.body || [];
          for (let i = 0; i < self.CustomerPaymentKeyDetails.length; i++) {
            if (self.CustomerPaymentKeyDetails[i].PaymentMethodsData) {
              let account = JSON.parse(self.CustomerPaymentKeyDetails[i].PaymentMethodsData);
              account = (account.data && account.data.length > 0) ? account.data[0] : account;
              if (account && account.us_bank_account) {
                self.PaymentMethodsDataList.push(account);
              } else if (account && account.card) {
                self.customerCardDetails.push(account);
              }
            }
          }
          self.PaymentMethodsDataList = self.uniqueList(self.PaymentMethodsDataList, false);
          self.customerCardDetails = self.uniqueList(self.customerCardDetails, true);
          self.customerCardDetails = self.customerCardDetails.length > 0 ? self.filteredCardDetails(self.customerCardDetails) : self.customerCardDetails;
          self.parent.currentPageLoading = false;
        }
        self.getAutoPayDetails();
        self.parent.currentPageLoading = false;
        console.log("PaymentMethodsDataList", self.PaymentMethodsDataList);
        console.log("customerCardDetails", self.customerCardDetails);
      }, err => {
        self.parent.currentPageLoading = false;
        console.log("ERROR IN StripeChargeCustomer:::", err);
      });
    }

    //sendToServer() {
    //    this.InvoicePaymentsService.getPaymentTokenFromServer().subscribe(resp => {
    //        if (resp.status == 200) {
    //            this.BRAINTREE_JWT_TOKEN = resp.body || '';
    //            this.createBrainTreePaymentConnection();
    //        }
    //    }, error => {
    //        if (error.error) {
    //            this.BRAINTREE_JWT_TOKEN = error.error.text || '';
    //            this.createBrainTreePaymentConnection();
    //        }
    //    });
    //}

    // sending nonce to server
    //sendNonceToServer(paymentNonce: string) {
    //    if (paymentNonce) {
    //        if (this.pendingInvoiceDetails && this.pendingInvoiceDetails.isPaymentMethodSaved) {
    //            this.chargeAmountToCustomer();
    //        } else {
    //            let nonceObj = {
    //                "CustomerId": this.pendingInvoiceDetails.Customer_Id,
    //                "PaymentMethodNonce": paymentNonce
    //                //'fake-valid-nonce'
    //            };
    //            this.InvoicePaymentsService.createCustomerGateway(nonceObj).subscribe(response => {
    //                let responseObject = JSON.parse(JSON.stringify(response));
    //                if (responseObject.body && responseObject.body.Status) {
    //                    this.chargeAmountToCustomer();
    //                } else {
    //                    this.paymentErrorOccurred = true;
    //                    this.dialogReferences.paymentDialogHandler.close();
    //                  this.parent.currentPageLoading = false;
    //                    document.getElementById("openPaymentStatusPopup").click();
    //                }
    //            }, err => {
    //                console.log("ERROR IN createCustomerGateway:::", err);
    //                this.paymentErrorOccurred = true;
    //                this.dialogReferences.paymentDialogHandler.close();
    //                this.parent.currentPageLoading = false;
    //                document.getElementById("openPaymentStatusPopup").click();
    //            });
    //        }
    //    }
    //}

    // charge amount to customer API
    //chargeAmountToCustomer() {
    //    let amountChargedToCustomer = {
    //        "CustomerId": this.selectedPendingInvoice.Customer_Id,
    //        "Amount": this.selectedPendingInvoice.AmountBilled,
    //        "InvoiceId": this.selectedPendingInvoice.Invoice_Id
    //    };
    //    this.InvoicePaymentsService.chargeCustomerAmount(amountChargedToCustomer).subscribe(response => {
    //        let responseObject = JSON.parse(JSON.stringify(response));
    //        if (responseObject.body && responseObject.body.Status) {
    //            this.paymentErrorOccurred = false;
    //            this.dialogReferences.paymentDialogHandler.close();
    //          this.parent.currentPageLoading = false;
    //            document.getElementById("openPaymentStatusPopup").click();
    //            this.pendingInvoiceDetails = [];
    //            this.selectedPendingInvoice = {};
    //            this.alertMessageDetails = {};
    //            this.loadAllPayments("duringPayment");
    //            setTimeout(() => {
    //              this.dialogReferences.paymentSummaryDialogHandler.close();
    //            }, 5000);
    //        } else {
    //            this.paymentErrorOccurred = true;
    //            this.dialogReferences.paymentDialogHandler.close();
    //          this.parent.currentPageLoading = false;
    //            document.getElementById("openPaymentStatusPopup").click();
    //        }
    //    }, err => {
    //        this.paymentErrorOccurred = true;
    //        this.dialogReferences.paymentDialogHandler.close();
    //        this.parent.currentPageLoading = false;
    //        document.getElementById("openPaymentStatusPopup").click();
    //    });
    //}

    // creating Brain tree connection
    //createBrainTreePaymentConnection() {
    //    let button = document.querySelector('#submitBrainTreePayment');
    //    if (this.BRAINTREE_JWT_TOKEN) {
    //        braintree.dropin.create({
    //            authorization: this.BRAINTREE_JWT_TOKEN,
    //            //'sandbox_g42y39zw_348pk9cgf3bgyw2b',
    //            container: '#paymentOptionsContainer'
    //        }, (createErr: any, instance: any) => {
    //            this.parent.currentPageLoading = false;
    //            this.paymentInstance = instance;
    //            button.removeEventListener("click", () => { });
    //            button.addEventListener('click', (e) => {
    //                e.stopImmediatePropagation();
    //                e.stopPropagation();
    //              this.parent.currentPageLoading = true;
    //              this.parent.loaderMessage = this.staticData.paymentLoading;
    //              this.parent.gif = this.staticData.paymentLoadingGifName;
    //              instance = this.paymentInstance;
    //                instance.requestPaymentMethod((err: any, payload: any) => {
    //                    if (payload && payload.nonce) {
    //                        this.sendNonceToServer(payload.nonce);
    //                    } else if (err) {
    //                        console.log("ERROR IN PAYMENT::::::::::", err);
    //                        this.paymentErrorOccurred = true;
    //                      this.parent.currentPageLoading = false;
    //                    }
    //                });
    //            });
    //        });
    //    }
    //}

    changeStartDate(dateObject: string) {
        if (dateObject) {
            this.paymentHistoryDateDetails.StartDateModel = dateObject;
        }
    }

    changeEndDate(dateObject: string) {
        if (dateObject) {
            this.paymentHistoryDateDetails.EndDateModel = dateObject;
        }
    }

    //Get All Payments for Payment History
    loadAllPayments(loaderFlag:string) {
      if (this.paymentHistoryDateDetails.StartDateModel && this.paymentHistoryDateDetails.EndDateModel) {
        if (loaderFlag == "duringPayment") {
            this.parent.gif = this.staticData.paymentLoadingGifName;
        } else {
            this.parent.gif = this.staticData.alohaLoadingGifName;
            this.parent.loaderMessage = "";
            this.parent.currentPageLoading = true;
        }
            var paymentHistoryRequestPayload = {
                "StartDate": this.paymentHistoryDateDetails.StartDateModel,
                "EndDate": this.paymentHistoryDateDetails.EndDateModel,
                "SubscriptionId": CONSTANTS.SUBSCRIPTIONID
            };
            this.InvoicePaymentsService.getAllPayments(paymentHistoryRequestPayload)
                .subscribe(response => {
                    if (response && response.body) {
                        this.paymentHistoryArray = response.body;
                        if (this.paymentHistoryArray && this.paymentHistoryArray.length > 0) {
                            this.paymentHistoryArray.sort((a, b) => {
                                return <any>new Date(b.PaymentDate) - <any>new Date(a.PaymentDate);
                            });
                            this.paymentHistoryArray.forEach(obj => {
                                obj.PaymentDateConverted = this.dateConversions.convertDateToMMDDYYYY(obj.PaymentDate);
                                obj.AmountPaid = parseFloat(obj.AmountPaid).toFixed(2);
                            });
                        }
                        if ((this.paymentHistoryArray && this.paymentHistoryArray.length > 0) ||
                            (this.pendingInvoiceDetailsCopy && this.pendingInvoiceDetailsCopy.length > 0)) {
                            this.noRecordsAvailable = false;
                        } else {
                            this.noRecordsAvailable = true;
                        }
                      this.parent.currentPageLoading = false;
                    }
                  this.parent.currentPageLoading = false;
                }, error => {
                  this.parent.currentPageLoading = false;
                    console.log("ERROR IN getAllPayments:::", error);
                });
        }
    }

    //updatePaymentStatus(invoices: any) {
    //  let data: any = {}, self = this;
    //  self.UpdatePaymentIntentStatusModel.PaymentIntentStatus = [];
    //  this.InvoicePaymentsService.getStripeKey().subscribe(response => {
    //    let responseObject = JSON.parse(JSON.stringify(response));
    //    if (responseObject && responseObject.body) {
    //      self.StripePublishableKey = responseObject.body.PublishableKey || self.StripePublishableKey;
    //      const stripe = Stripe(self.StripePublishableKey);
    //      for (let i = 0; i < invoices.length; i++) {
    //        if (invoices[i].ClientSecret && invoices[i].PaymentStatus !== "succeeded") {
    //          stripe.retrievePaymentIntent(invoices[i].ClientSecret).then(function (result) {
    //            data.Invoice_Id = invoices[i].Invoice_Id;
    //            data.PaymentStatus = result.paymentIntent.status;
    //            self.UpdatePaymentIntentStatusModel.SubscriptionId = self.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID;
    //            self.UpdatePaymentIntentStatusModel.PaymentIntentStatus.push(data);
    //          });
    //        }
    //      }
    //      console.log("self.UpdatePaymentIntentStatusModel", self.UpdatePaymentIntentStatusModel);
    //    }
    //  }, err => {
    //    console.log("ERROR IN getStripeKey:::", err);
    //  });
    //}

    OpenAutomaticPaymentSetupDialog(templateRef: TemplateRef<any>){
      this.showValidationMessage = false;
      this.validatedMessage = "";
      let data = JSON.parse(this.globalConfig.getCookie('backupForAutomaticPayment'));
      if (data && data.automaticPayment) {
        this.automaticPayment = data.automaticPayment;
        this.paymentMethodLast4 = data.paymentMethodLast4;
        this.isCardSelected = data.isCardSelected;
        this.customerCardDetails = this.uniqueList(this.customerCardDetails, true);
        let cardPayment = this.customerCardDetails.length > 0 ? this.customerCardDetails.find(c => c.id === this.automaticPayment.PaymentMethodID) : null;
        let bankAccountPayment = this.PaymentMethodsDataList.length > 0 ? this.PaymentMethodsDataList.find(c => c.id === this.automaticPayment.PaymentMethodID) : null;
        if (cardPayment) {
          this.autoPaymentMode = cardPayment;
        } else if (bankAccountPayment) {
          this.autoPaymentMode = bankAccountPayment;
        }
      } else {
        this.automaticPayment = {
          "IsAutoDebit": true,
          "RMD_Mail": true,
          "CNF_PYMT_Mail": false,
          "CNF_Config_Mail": false,
          "Rej_Mail": false,
          "Autopay_Id": -1
        };
      }
      this.dialogReferences.automaticPaymentSetupDialog = templateRef;
      this.dialogReferences.automaticPaymentSetupHandler = this.dialog.open(templateRef);
    }

    validateEmail(email) {
      const pattern = /^((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))$/;

      return pattern.test(email);
    }

  openConfirmAutomaticPaymentSetup(templateRef: TemplateRef<any>) {
    const primaryInputElement = document.getElementById("primary") as HTMLInputElement | null;
    const secondaryInputElement = document.getElementById("secondary") as HTMLInputElement | null;
      if (!this.automaticPayment.Email_id) {
        if (primaryInputElement) {
          primaryInputElement.style.border = "1px solid red";
          primaryInputElement.focus();
        } else {
          console.error('Input element not found');
        }
        this.validatedMessage = "Oh! Mandatory fields are required";
        this.showValidationMessage = true;
        return false;
      } else if (this.automaticPayment.Email_id && !this.validateEmail(this.automaticPayment.Email_id)) {
        primaryInputElement.style.border = "1px solid red";
        primaryInputElement.focus();
        this.validatedMessage = "Email is not in valid format";
        this.showValidationMessage = true;
        return false;
      } else if (this.automaticPayment.SecondaryEmail && !this.validateEmail(this.automaticPayment.SecondaryEmail)) {
        secondaryInputElement.style.border = "1px solid red";
        secondaryInputElement.focus();
        this.validatedMessage = "Email is not in valid format";
        this.showValidationMessage = true;
        return false;
      } else if (this.automaticPayment.Email_id && this.automaticPayment.SecondaryEmail && this.automaticPayment.Email_id.toLowerCase() === this.automaticPayment.SecondaryEmail.toLowerCase()) {
        this.validatedMessage = "Primary and Secondary email address can't be the same.";
        this.showValidationMessage = true;
        return false;
      } else {
        this.validatedMessage = "";
        this.showValidationMessage = false;
        primaryInputElement.style.border = "none";
        secondaryInputElement.style.border = "none";
      }
      this.dialogReferences.confirmAutomaticPaymentSetupDialog = templateRef;
      this.dialogReferences.confirmAutomaticPaymentSetupHandler = this.dialog.open(templateRef, {
        panelClass: 'custom-dialog-width'
      });
    }

    updateTab(){
      this.paymentMethodLast4 = null;
      this.autoPaymentMode = null;
      this.isCardSelected = !this.isCardSelected;
    }

    selectPaymentMethod(pamentMethod: any, isCard: boolean) {
      if (isCard) {
        this.paymentMethodLast4 = pamentMethod.card.last4;
      } else {
        this.paymentMethodLast4 = pamentMethod.us_bank_account.last4;
      }
    }

    getButtonLAbel(status: string) {
      let label = "Make Payment";
      switch (status) {
        case "processing":
          label = "Processing";
          break;
        case "requires_action":
          label = "Verify with microdeposits";
          break;
        case "requires_payment_method":
          label = "Make Repayment";
      }
      return label;
    }

    openTermsPage(): void {
      const fullUrl = `${window.location.origin}/#/terms`;
      window.open(fullUrl, '_blank');
    }

    getCurrentMonth15th() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();
      const day = 15;

      const formattedMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
      const formattedDay = day < 10 ? '0' + day : day;
      return `${formattedMonth}/${formattedDay}/${currentYear}`;
    };

    getNextMonth15th() {
      const today = new Date();
      let nextMonth = today.getMonth() + 2; 
      let year = today.getFullYear();

      if (nextMonth > 12) {
          nextMonth = 1;
          year += 1;
      }
      const day = 15; 

      const formattedMonth = nextMonth < 10 ? '0' + nextMonth : nextMonth;
      const formattedDay = day < 10 ? '0' + day : day;

      return `${formattedMonth}/${formattedDay}/${year}`;
    };

    isCurrentMonth(dateString: string) {
      const inputDate = new Date(dateString);
      const today = new Date();

      const inputYear = inputDate.getFullYear();
      const inputMonth = inputDate.getMonth();

      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();

      return inputYear === currentYear && inputMonth === currentMonth;
    };
     
    getAtoPaymentStatusInfo(last4:any) {
      let dateFormat = this.getCurrentMonth15th();
      if (this.isCurrentMonthInvoicePaid) {
        dateFormat = this.getNextMonth15th();
      }
      const paymentType: string = this.isCardSelected ? "card" : "bank account";
      const autoPaymentStatusInfo: any = {
        "name": "Automatic Payment is ON",
        "color": "abaSuccess_Info",
        "borderColor": "abaSuccessInfoBrdr",
        "title": "Automatic Payment is ON",
        "message": "Your next automatic payment is scheduled for " + dateFormat + ". The amount will be debited from your [" + paymentType + "] ending in [****" + last4 + "]"
      }
      return autoPaymentStatusInfo;
    }

    filteredCardDetails(data:any){
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      let paymentId = null;
      for (let i = 0; i < data.length; i++) {
        if (currentYear > data[i].card.exp_year || (currentYear === data[i].card.exp_year && currentMonth > data[i].card.exp_month)) {
          paymentId = data[i].id;
          break;
        }
      }
      const updatedData = data.filter(payment => payment.id !== paymentId);
      return updatedData;
    }

    getPaymentMethodDetails(id:string, type:string){
      let paymentId = {
        "PaymentMethod_Id": id
      };
      let self = this;
      self.InvoicePaymentsService.getPaymentMethodDetails(paymentId).subscribe(response => {
        if (response && response.body) {
          let payment: any = response.body;
          self.autoPaymentMode = JSON.parse(payment);
          if (type === "card") {
            self.customerCardDetails.push(self.autoPaymentMode);
            self.customerCardDetails = self.uniqueList(self.customerCardDetails, true);
            self.isCardSelected = true;
          } else {
            self.PaymentMethodsDataList.push(self.autoPaymentMode);
            self.PaymentMethodsDataList = self.uniqueList(self.PaymentMethodsDataList, false);
            self.isCardSelected = false;
          }
          self.backupForAutomaticPayment.autoPaymentMode = self.autoPaymentMode;
          self.backupForAutomaticPayment.isCardSelected = self.isCardSelected;
          self.globalConfig.setCookie('backupForAutomaticPayment', JSON.stringify(self.backupForAutomaticPayment), 1, false);
        }
      }, error => {
        console.log("ERROR IN getPaymentMethodDetails:::", error);
      })
    }

    getAutoPayDetails(){
      let subscriptionId = this.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID, self = this;
      self.AutoPayStatusLabel = "";
      self.InvoicePaymentsService.getAutoPayDetail(subscriptionId).subscribe(response => {
        if (response && response.body) {
          self.automaticPayment = response.body;
          self.paymentMethodLast4 = self.automaticPayment.PaymentInstrument;
          self.customerCardDetails = self.customerCardDetails.length > 0 ? self.filteredCardDetails(self.customerCardDetails) : self.customerCardDetails;
          let cardPayment = self.customerCardDetails.length > 0 ? self.customerCardDetails.find(c => c.card.last4 === self.automaticPayment.PaymentInstrument) : null;
          let bankAccountPayment = self.PaymentMethodsDataList.length > 0 ? self.PaymentMethodsDataList.find(c => c.us_bank_account.last4 === self.automaticPayment.PaymentInstrument) : null;
          if (cardPayment) {
            self.autoPaymentMode = cardPayment;
            self.isCardSelected = true;
          } else if (bankAccountPayment) {
            self.autoPaymentMode = bankAccountPayment;
            self.isCardSelected = false;
          }
          self.backupForAutomaticPayment.automaticPayment = self.automaticPayment;
          self.backupForAutomaticPayment.paymentMethodLast4 = self.paymentMethodLast4;
          self.backupForAutomaticPayment.autoPaymentMode = self.autoPaymentMode;
          self.backupForAutomaticPayment.isCardSelected = self.isCardSelected;
          self.globalConfig.setCookie('backupForAutomaticPayment', JSON.stringify(self.backupForAutomaticPayment), 1, false);
          if (self.automaticPayment && self.automaticPayment.Autopay_Id > 0 && self.automaticPayment.IsAutoDebit) {
            self.showAutoPayLabel = true;
            self.autoPaymentStatusInfo = self.getAtoPaymentStatusInfo(self.paymentMethodLast4);
            self.AutoPayStatusLabel = "ON";
          } else {
            self.showAutoPayLabel = false;
            self.AutoPayStatusLabel = "OFF";
          }
          self.getPaymentMethodDetails(self.automaticPayment.PaymentMethodID, self.automaticPayment.PaymentMethodType);
        } else {
          self.globalConfig.setCookie('backupForAutomaticPayment', null, 1, false);
          self.showAutoPayLabel = false;
          self.AutoPayStatusLabel = "OFF";
        }
      }, error => {
        console.log("ERROR IN getAutoPayDetail:::", error);
      });
    }

    addOrUpdateAutoPayment(){
      this.parent.gif = this.staticData.paymentLoadingGifName;
      this.parent.currentPageLoading = true;
      let data: any = this.automaticPayment;
      data.Autopay_Id = data.Autopay_Id > 0 ? data.Autopay_Id : -1;
      data.Subscription_id = this.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID;
      data.LogedInUserEmail = this.globalConfig.getCookie('UserName');
      if (this.autoPaymentMode && this.autoPaymentMode.card) {
        data.PaymentMethodType = "card";
        data.PaymentRefernce_Id = this.autoPaymentMode.customer;
        data.PaymentMethodID = this.autoPaymentMode.id;
        data.PaymentInstrument = this.autoPaymentMode.card.last4;
      } else {
        data.PaymentMethodType = "us_bank_account";
        data.PaymentRefernce_Id = this.autoPaymentMode.customer;
        data.PaymentMethodID = this.autoPaymentMode.id;
        data.PaymentInstrument = this.autoPaymentMode.us_bank_account.last4;
      }
      data.SecondaryEmail = data.SecondaryEmail ? data.SecondaryEmail : "";
      this.InvoicePaymentsService.addOrUpdateAutoPay(data).subscribe(response => {
        this.getAutoPayDetails();
        this.parent.currentPageLoading = false;
        this.dialogReferences.automaticPaymentSetupHandler.close();
        this.dialogReferences.confirmAutomaticPaymentSetupHandler.close();
      }, error => {
        console.log("ERROR IN addOrUpdateAutoPay:::", error);
      });
    }

    getInvoices() {
        //Get All Invoices
        //this.InvoicePaymentsService.getPendingInvoice(CONSTANTS.SUBSCRIPTIONID)
        let self = this;
        self.isCurrentMonthInvoicePaid = false;
        this.InvoicePaymentsService.getAllInvoices(CONSTANTS.SUBSCRIPTIONID)
            .subscribe(response => {
                if (response && response.body) {
                    this.pendingInvoiceResponse = response.body;
                    if (this.pendingInvoiceResponse && this.pendingInvoiceResponse.length > 0) {
                      var data: any = {};
                      data.SubscriptionId = self.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID;
                      data.PaymentIntentStatus = self.pendingInvoiceResponse;
                      self.InvoicePaymentsService.updatePaymentIntentStatus(data).subscribe(response => {
                        self.parent.currentPageLoading = false;
                        if (response && response.body) {
                          this.pendingInvoiceDetails = response.body;
                          let isPendingInvoice = false;
                          let checkDifferenceOfDates = 0;
                          let alertMessageStatus = "";
                          for (var i = 0; i < this.pendingInvoiceDetails.length; i++) {
                            this.pendingInvoiceDetails[i].StatementDate = this.dateConversions.convertDateToMMDDYYYY(this.pendingInvoiceDetails[i].InvoiceGeneratedDate);
                            this.pendingInvoiceDetails[i].DueDateConverted = this.dateConversions.convertDateToMMDDYYYY(this.pendingInvoiceDetails[i].DueDate);
                            this.pendingInvoiceDetails[i].AmountBilled = parseFloat(this.pendingInvoiceDetails[i].AmountBilled).toFixed(2);
                            if (this.pendingInvoiceDetails[i].PaymentStatus !== "succeeded") {
                              this.pendingInvoiceDetails[i].ButtonLabel = this.getButtonLAbel(this.pendingInvoiceDetails[i].PaymentStatus);
                            } else if (this.pendingInvoiceDetails[i].PaymentStatus === "succeeded") {
                              this.pendingInvoiceDetails[i].ButtonLabel = "Processing";
                            }
                            if (this.pendingInvoiceDetails[i].IsPending) {
                              isPendingInvoice = true;
                            }
                            checkDifferenceOfDates = 0;
                            if (this.pendingInvoiceDetails[i].PaymentDisplayText == "Payment Due") {
                              checkDifferenceOfDates = this.dateConversions.getNumberOfDaysBetweenDates(<any>new Date(), this.pendingInvoiceDetails[i].DueDate);
                              if (checkDifferenceOfDates > 0) {
                                this.pendingInvoiceDetails[i].PaymentDisplayText = "Past Payment Due";
                                alertMessageStatus = "PAST_DUE";
                              } else if (checkDifferenceOfDates <= 0) {
                                alertMessageStatus = alertMessageStatus ? alertMessageStatus : "DUE";
                              }
                            }
                            if (self.isCurrentMonth(this.pendingInvoiceDetails[i].DueDate) && this.pendingInvoiceDetails[i].IsPaid) {
                              self.isCurrentMonthInvoicePaid = true;
                            }
                          }
                          if (isPendingInvoice) {
                            this.alertMessageDetails = this.paymentStatusClv[alertMessageStatus];
                          }
                          this.pendingInvoiceDetails.sort((a, b) => {
                            return <any>new Date(b.InvoiceGeneratedDate) - <any>new Date(a.InvoiceGeneratedDate);
                          });
                          this.pendingInvoiceDetailsCopy = JSON.parse(JSON.stringify(this.pendingInvoiceDetails));
                          this.filterInvoices();
                          this.getCustomerCardDetails(CONSTANTS.SUBSCRIPTIONID);
                          this.loadAllPayments('');
                        }
                      }, err => {
                        console.log("ERROR IN updatePaymentIntentStatus:::", err);
                      });
                    }
                }
            }, error => {
                console.log("ERROR IN getPendingInvoice:::", error);
                this.loadAllPayments('');
            });
    }

    //autoSaveInterval = setInterval(function () {
    //  this.getInvoices();
    //}.bind(this), 5000);
    //autoPay(){
    //  let data: any = {};
    //  data.CustomerEmail = "siribhupathi850@gmail.com";
    //  data.SubscriptionId = "94de4bd2-0274-41bd-8026-d61f67262cbd";
    //  data.PaymentMethodId = "pm_1Pkk7rCGVSdY2db1w5y6haai";
    //  data.InvoiceId = 8491
    //  data.PaymentRefernce_Id = "cus_Qby3RLQs2HiYPm";
    //  data.Amount = 2339.22
    //  data.PaymentMethodType = "us_bank_account";

    //  this.InvoicePaymentsService.autoPay(data).subscribe(response => {
    //    console.log("autoPay::", response.body);
    //  });
    //}

    ngOnInit() {
        var tempSubscriptionId = this.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID;
        CONSTANTS.SUBSCRIPTIONID = tempSubscriptionId;
        this.sixMonthPreviousDate.setMonth(this.sixMonthPreviousDate.getMonth() - 6);
        this.paymentHistoryDateDetails.StartDateModel = this.sixMonthPreviousDate;
        this.getInvoices();
    }
}
