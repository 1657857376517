import { Directive, OnInit, Input, ElementRef, Renderer } from '@angular/core';

@Directive({ selector: '[aba-sort-column]' })

export class abaSortDirective implements OnInit {
  @Input() data: any[];
  @Input('sortKey') key: any;
  private toggleSort: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer) {
  }

  ngOnInit() {
    this.renderer.listen(this.el.nativeElement, 'click', (event) => {
      if (this.data && this.key) {
        this.sortArray();
      }
      this.toggleSort = !this.toggleSort;
    });
  }

  sortArray(): Array<any> {
    let tempArray: Array<any> = this.data;
    tempArray.sort((a, b) => {
      let aKey = a[this.key];
      let str1: any, str2: any;
      str1 = a[this.key];
      str2 = b[this.key];
      if (typeof str1== 'string') {
        str1 = a[this.key].toLowerCase();      
      }
      if (typeof str2 == 'string') {
        str2 = b[this.key].toLowerCase();
      }
      if (this.toggleSort) {
        if (str1 < str2) {
          return -1;
        }
        if (str1 > str2) {
          return 1;
        }
      }
      else {
        if (str1 > str2) {
          return -1;
        }
        if (str1 < str2) {
          return 1;
        }
      }
      return 0;
    });
    return tempArray;
  }
}
