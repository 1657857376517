/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../Scripts/layout/componentHeader.component.ngfactory";
import * as i2 from "../../Scripts/layout/componentHeader.component";
import * as i3 from "../../Scripts/Global/GlobalConfig";
import * as i4 from "@angular/router";
import * as i5 from "./home.component";
var styles_HomeComponent = [];
var RenderType_HomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "component-header", [], null, null, null, i1.View_componentHeaderComponent_0, i1.RenderType_componentHeaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.componentHeaderComponent, [i3.GlobalConfig, i4.ActivatedRoute], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "abaComponent_Container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "label", [["class", "abaHeader5"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Home Component"]))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i0.ɵdid(1, 49152, null, 0, i5.HomeComponent, [], null, null)], null, null); }
var HomeComponentNgFactory = i0.ɵccf("app-home", i5.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
