import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

/**
	* @name  datepicker.component:abaDatepicker
	*@description
	* This is date picker for this project.
	* @param { Object }  abaId - unique id to directive.
	* @param { Object }  datepickerModel - ng model binded to directive.
	* @param {Date=}  min - min date Object
	* @param {Date=}  max - max date Object
	* @param {function=}  datepickerChange - call back method for ng change event
	* @param {boolean=}  datepickerShow - boolean to show datepicker
	* @param {boolean=}  datepickerDisabled - boolean to disable datepicker
	* @param {string=}  datepickerPlaceholder - placeholder/title for datepicker
	* @param {boolean=}  datepickerRequired- boolean for required
	* @example
				<aba-datepicker abaId="example_Id" [datepickerPlaceholder]="{Placeholder}" [datepickerRequired]="false" [maxDate]="maxdate"  [minDate]="mindate" [datepickerDisabled]="false" (datepickerChange)="changeDate()"></aba-datepicker>
	**/

@Component({
  selector: 'aba-datepicker',
  template: `<mat-form-field>
                <input matInput [id]="abaId" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_abaId" [placeholder]="datepickerPlaceholder" [(ngModel)]="datepickerModel" [required]="datepickerRequired" [disabled]="datepickerDisabled"
                    (dateInput)="addEvent('input', $event, '')" (dateChange)="addEvent('change', $event,datepickerModel);">
                <mat-datepicker-toggle matSuffix [for]="picker_abaId"></mat-datepicker-toggle>
                <mat-datepicker #picker_abaId></mat-datepicker>
            </mat-form-field>`,
})

export class AbaDatepicker implements OnInit{
  @Input() datepickerModel: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() datepickerChange: EventEmitter<string> = new EventEmitter();
  @Input() datepickerDisabled: any;
  @Input() datepickerShow: any;
  @Input() datepickerPlaceholder: string;
  @Input() datepickerRequired: boolean;
  @Input() abaId: string;

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>,model:string) {
    this.events.push(`${type}: ${event.value}`);
    if (this.datepickerChange) {
      this.datepickerChange.emit(this.datepickerModel);
    }
  }

  constructor() { }

  ngOnInit() { }
}
