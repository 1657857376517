import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '../CONSTANTS';
@Injectable()

export class NewSubscriberService {
    constructor(private http: HttpClient) { }
  
    sendCustomerSignupDetails(subscriberDetails) {
        return this.http.post(CONSTANTS.ADMIN_PORTAL_API_URL + "CustomerSignup/AutoSignupCustomer", subscriberDetails, { observe: 'response' });
    }

    isEmailValid(email) {
        return this.http.post(CONSTANTS.ADMIN_PORTAL_API_URL + "CustomerSignup/IsEmailValid", JSON.stringify(email), { observe: 'response', headers: { "Content-type": "application/json" } });
    }
}
