import { Component, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { NewSubscriberService } from './NewSubscriber.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { CONSTANTS } from '../CONSTANTS';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'new-subscriber',    
    templateUrl: '../../views/User/newSubscriber.component.html',    
    styleUrls: ['../../Views/User/abaUserSignup.scss']
})
export class NewSubscriberComponent {
    testpattern = new RegExp("[^a-zA-Z 0-9!%@#^$&*|?~()-_`><.+=,/\"\'{}[]|:;\?\>\<\\]", "ig");
    //"^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{1,50}$";
    pattern = "^([a-zA-Z 0-9]){1,50}$";
    phoneNumberPattern = "^([0-9]){7,10}$";
    siteUrlPattern = "^([a-zA-Z0-9]){1,50}$";
    accountInfoForm: FormGroup;
    organizationInfoForm: FormGroup;
    hide = true;
    formToggle  = true;
    newSubscriberFormGroupArray = [
        this.accountInfoForm = new FormGroup({
            firstName: new FormControl('', [
                Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
            ]),
            lastName: new FormControl('', [
                Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
            ]),
            email: new FormControl('', [
                Validators.required, Validators.email
            ]),
            phone: new FormControl('', [Validators.pattern(this.phoneNumberPattern)]),
            organizationName: new FormControl('', [
                Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
            ]),
            siteUrl: new FormControl('', []),
            acronym: new FormControl('', [
                Validators.required, Validators.pattern(this.siteUrlPattern), Validators.minLength(1), Validators.maxLength(50)
            ]),
            submitted: new FormControl('', [])
        }),
        //this.organizationInfoForm = new FormGroup({
        //    organizationName: new FormControl('', [
        //        Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
        //    ]),
        //    siteUrl: new FormControl('', []),
        //    acronym: new FormControl('', [
        //        Validators.required, Validators.pattern(this.siteUrlPattern), Validators.minLength(1), Validators.maxLength(50)
        //    ]),
        //    submitted: new FormControl('', [])
        //})
    ];
    fieldNames = {
      //  firstName: "First Name",
     //   lastName: "Last Name",
     //   email: "Email",
     //   phone: "Phone",
    //    organizationName: "Organization Name",
    //    acronym: "Site URL",
    };
    wizardTabDataTargets = ["#newSubscription_AccountInfo", "#newSubscription_OrgInfo", "#newSubscription_Setup"];
    wizardTabs = [
        {
            tabName: "ACCOUNT INFO",
            tabIcon: "&#10112;",
            dataTarget: this.wizardTabDataTargets[0],
            isEdit: false,
            isActive: true,
            isValidatedSuccess: false,
            isSetupLoading: false,
            isSetupActive: false
        },
        {
            tabName: "ORGANIZATION INFO",
            tabIcon: "&#10113;",
            dataTarget: "",
            isEdit: false,
            isActive: false,
            isValidatedSuccess: false,
            isSetupLoading: false,
            isSetupActive: false
        },
        {
            tabName: "SETUP",
            tabIcon: "&#10114;",
            dataTarget: "",
            isEdit: false,
            isActive: false,
            isValidatedSuccess: false,
            isSetupLoading: false,
            isSetupActive: false
        }
    ];
    currentActiveTab = this.wizardTabDataTargets[0];
    showBackButton = false;
    showNextButton = true;
    showFinishButton = false;
    siteUrlSuffix = CONSTANTS.DOMAIN;
    staticData: any = {
      //  paymentLoadingGifName: "aba_Loader.gif",
      //  alohaLoadingGifName: "aloha_Loader.gif",
      //  loadingMessage: "Please wait while we process your request."
    };
    subscriberCreationStatus = "";
    formErrors = {
        errors: {},
        errorFields: [],
    };

    constructor(private NewSubscriberService: NewSubscriberService, private parent: AppComponent, private dialog: MatDialog) {
    }

    AddNewCustomer = function () {
        var currentTabIndex = this.wizardTabDataTargets.indexOf(this.currentActiveTab);
        //for (var i = 0; i < this.newSubscriberFormGroupArray.length; i++) {
        for (var i = 0; i <= currentTabIndex; i++) {
            if (this.currentActiveTab == this.wizardTabDataTargets[i]) {
                this.newSubscriberFormGroupArray[i].submitted = true;
                this.formErrors.submitted = true;
            }
            this.formErrors.errorFields = [];
            this.formErrors.errors = {};
            try {
                if (this.newSubscriberFormGroupArray[i].invalid) {
                    for (var j in this.newSubscriberFormGroupArray[i].controls) {
                        if (this.newSubscriberFormGroupArray[i].controls[j].errors) {
                            this.newSubscriberFormGroupArray[i].errors = {};
                            if (this.newSubscriberFormGroupArray[i].controls[j].errors.required) {
                                this.newSubscriberFormGroupArray[i].errors.required = true;
                                this.formErrors.errors.required = true;
                                break;
                            } else if (this.newSubscriberFormGroupArray[i].controls[j].errors.pattern) {
                                this.formErrors.errors.pattern = true;
                                this.formErrors.errorFields.push(this.fieldNames[j]);
                                //break;
                            }
                            else if (this.newSubscriberFormGroupArray[i].controls[j].errors.email) {
                                this.formErrors.errors.pattern = true;
                                this.formErrors.errorFields.push(this.fieldNames[j]);
                                //break;
                            }
                        }
                    }
                    this.formErrors.errorFieldNames = this.formErrors.errorFields.join(", ");
                    break;
                }
            } catch (e) {
                console.log("EXCEPTION in AddNewCustomer", e);
            }
            if (!this.newSubscriberFormGroupArray[i].invalid && this.currentActiveTab == this.wizardTabDataTargets[i]) {
                this.parent.currentPageLoading = true;
                if (currentTabIndex == i) {
                    if (currentTabIndex == 0) {
                        this.NewSubscriberService.isEmailValid(this.accountInfoForm.value.email).subscribe(data => {
                            if (data && data.status == 200 && data.body) {
                                this.parent.currentPageLoading = false;
                                if (currentTabIndex == this.wizardTabDataTargets.length - 2) {
                                    this.wizardTabs[i].isEdit = false;
                                }
                                if (currentTabIndex < this.wizardTabDataTargets.length - 2) {
                                    this.wizardTabs[i].isEdit = true;
                                }
                                if (i >= 0 && currentTabIndex <= this.wizardTabDataTargets.length - 3) {
                                    this.showBackButton = true;
                                    this.showNextButton = true;
                                }
                                if (currentTabIndex == this.wizardTabDataTargets.length - 3) {
                                    this.showNextButton = false;
                                    this.showFinishButton = true;
                                }
                                this.wizardTabs[i].isActive = false;
                                this.wizardTabs[i].isValidatedSuccess = true;
                                this.currentActiveTab = this.wizardTabDataTargets[i + 1];
                                this.wizardTabs[i + 1].isActive = true;
                                this.formErrors.submitted = false;
                            } else {
                                this.parent.currentPageLoading = false;
                                this.formErrors.errors.duplicate = true;
                                this.formErrors.errorFields.push(this.accountInfoForm.value.email);
                                this.formErrors.errorFieldNames = this.accountInfoForm.value.email;
                            }
                        }, err => {
                                console.log("Error in isEmailValid......", err);
                        });
                    }
                    if (currentTabIndex == this.wizardTabDataTargets.length - 2) {
                        //this.parent.currentPageLoading = true;
                        var subscriberDetails = {
                           // "firstName": this.accountInfoForm.value.firstName,
                           // "lastName": this.accountInfoForm.value.lastName,
                          //  "email": this.accountInfoForm.value.email,
                         //   "phoneNumber": this.accountInfoForm.value.phone,
                        //    "organisationName": this.organizationInfoForm.value.organizationName,
                        //    "siteUrl": this.organizationInfoForm.value.acronym + this.siteUrlSuffix,
                        //    "acronym": this.organizationInfoForm.value.acronym,
                       //     "subscriptionId": ""
                        }
                        this.NewSubscriberService.sendCustomerSignupDetails(subscriberDetails).subscribe(data => {
                            if (data && data.status == 200 && data.body) {
                                this.parent.currentPageLoading = false;
                                if (data.body.status) {
                                    this.wizardTabs[i].isEdit = false;
                                    this.showBackButton = false;
                                    this.showNextButton = false;
                                    this.showFinishButton = false;
                                    this.wizardTabs[i].isActive = false;
                                    this.wizardTabs[i].isValidatedSuccess = true;
                                    this.currentActiveTab = this.wizardTabDataTargets[i + 1];
                                    this.wizardTabs[i + 1].isActive = true;
                                    this.formErrors.submitted = false;
                                    for (let i = 0; i < this.wizardTabs.length; i++) {
                                        setTimeout(() => {
                                            this.wizardTabs[i].isSetupLoading = true;
                                        }, i * 400);
                                        setTimeout(() => {
                                            this.wizardTabs[i].isSetupActive = true;
                                            this.wizardTabs[i].isSetupLoading = false;
                                            if (i == this.wizardTabs.length - 1) {
                                                this.subscriberCreationStatus = "success";
                                            }
                                        }, i * 1000);
                                    }
                                }
                                else {
                                    //this.parent.currentPageLoading = false;
                                    this.formErrors.errors.duplicate = true;
                                    this.formErrors.errorFields.push(this.organizationInfoForm.value.acronym);
                                    this.formErrors.errorFieldNames = this.organizationInfoForm.value.acronym;
                                }
                            } else {
                                this.subscriberCreationStatus = "fail";
                                this.parent.currentPageLoading = false;
                            }
                        }, err => {
                                this.subscriberCreationStatus = "fail";
                                this.parent.currentPageLoading = false;
                                console.log("Error in sendCustomerSignupDetails......", err);
                        });
                    }
                }            
                break;
            }
        }
    }

    showWizardTab = function (tabIndex: number) {
        if (tabIndex >= 0 && this.wizardTabs[tabIndex].isEdit) {
            for (var i = 0; i < this.wizardTabs.length; i++) {
                if (i >= tabIndex) {
                    this.wizardTabs[i].isActive = false;
                    this.wizardTabs[i].isValidatedSuccess = false;
                    this.wizardTabs[i].isEdit = false;
                }
                this.wizardTabs[i].isSetupActive = false;
                this.wizardTabs[i].isSetupLoading = false;
            }
            this.formErrors.submitted = false;
            this.wizardTabs[tabIndex].isActive = true;
            this.wizardTabs[tabIndex].isEdit = false;
            this.wizardTabs[tabIndex].isValidatedSuccess = false;
            this.currentActiveTab = JSON.parse(JSON.stringify(this.wizardTabDataTargets[tabIndex]));
            if (tabIndex >= 0 && tabIndex <= this.wizardTabDataTargets.length - 3) {
                this.showBackButton = true;
                this.showNextButton = true;
                if (tabIndex == 0) {
                    this.showBackButton = false;
                }
            }
            if (tabIndex < this.wizardTabDataTargets.length-2) {
                this.showFinishButton = false;
            }
        }
    }

    backFunction = function () {
        this.showWizardTab(this.wizardTabDataTargets.indexOf(this.currentActiveTab)-1);
    }

    organizationNameChange = function () {
        if (this.organizationInfoForm.value.organizationName) {
            var orgNameArray = this.organizationInfoForm.value.organizationName.split(" ");
            if (orgNameArray.length > 0) {
                var tempSiteUrl = "";
                for (var i = 0; i < orgNameArray.length; i++) {
                    tempSiteUrl += orgNameArray[i].charAt(0);
                }
                tempSiteUrl = tempSiteUrl.toLowerCase();
            }
            this.organizationInfoForm.patchValue({ acronym: tempSiteUrl });
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.parent.currentPageLoading = false;
            this.parent.loaderMessage = this.staticData.loadingMessage;
        });
    }
}
