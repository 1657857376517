
<component-header></component-header>
<!--Subscription Management Container Html-->
<div class="abaBody_Container subscriptionContainer" *ngIf="HasFullAccess">
  <!--Subscription Header-->
  <div class="subscriptionHeader">
    <label class="abaHeader5 abaBold">Current Subscription Plan Details</label>
  </div>
  <!--end of Subscription Header-->
  <!--Subscription Body-->
  <div class="subscriptionBody"  [ngClass]="{'Auto_subscriptionBody':!IsModified}">
    <div class="row">
      <div class="col-md-12 col-xl-9">
        <div class="row abaPdng_tp_10 abaPdng_btm_10 ababrdr_btm_1">
          <div class="col-md-6 col-xl-4 ">
            <label class="abaLabel abaBold">Start Date:</label>
            <label class="abaLabel abaPdng_lft_5">{{StartDate|date:'mediumDate'}}</label>
          </div>
          <div class="col-md-6 col-xl-4 ">
            <label class="abaLabel abaBold">Subscription Period:</label>
            <label class="abaLabel abaPdng_lft_5">One Month</label>
          </div>
        </div>
        <div class="row abaPdng_tp_10 abaPdng_btm_10 ababrdr_btm_1 d-none">
          <div class="col-md-6 col-xl-4 ">
            <label class="abaLabel abaBold">End Date:</label>
            <label class="abaLabel abaPdng_lft_5">{{EndDate|date:'mediumDate'}}	</label>
          </div>
        </div>
        <!-- Base Features -->
        <div class="baseFeatures ababrdr_btm_1 abaPdng_tp_15">
          <label class="abaHeader6 abaBold">Base Features</label>
          <div class="row">
            <div class="col-md-6 col-xl-7">
              <div class="abaPdng_10" *ngFor="let baseFeature of baseFeatures">
                <div class="d-flex">
                  <label class="abaLabel abaBold">{{baseFeature.FeatureName}}</label><i class="abaIcon abaMrgn_lft_10 abaMrgn_tp_5 cursorPointer" icon-name="iInfo-sm" #baseFeaturesTooltip="matTooltip" (click)="baseFeaturesTooltip.toggle()" matTooltip="{{baseFeature.Description}}" matTooltipPosition="above" matTooltipClass="aba_featureTootip"></i>
                </div>
                <label class="abaLabel d-block" *ngIf="false">Account Receivable management Billed per client</label>
              </div>
            </div>
            <div class="col-md-2 col-xl-2">
              <label class="abaLabel" *ngIf="baseFeaturesPrice">${{baseFeaturesPrice}}</label>
            </div>
            <div class="col-md-4 col-xl-3 abaPdng_rgt_20 pointerNone" align="right">
              <mat-button-toggle-group name="fontStyle" #group="matButtonToggleGroup" [(value)]="baseFeature" [attr.disabled]="true">
                <mat-button-toggle [value]="true">Enable</mat-button-toggle>
                <mat-button-toggle [value]="false">Disable</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        <!--end of Base Features -->
        <!-- Addditional Features -->
        <div class="addditionalFeatures abaPdng_tp_15">
          <label class="abaHeader6 abaBold">Additional Features</label>
          <div class="row" *ngFor="let additionalFeature of additionalFeatures">
            <div class="col-md-6 col-xl-7">
              <div class="abaPdng_10">
                <div class="d-flex">
                  <label class="abaLabel abaBold">{{additionalFeature.FeatureName}}</label><i class="abaIcon abaMrgn_lft_10 abaMrgn_tp_5 cursorPointer" icon-name="iInfo-sm" #additionalFeaturesTooltip="matTooltip" (click)="additionalFeaturesTooltip.toggle()" matTooltip="{{additionalFeature.Description}}" matTooltipPosition="above" matTooltipClass="aba_featureTootip"></i>
                </div>
                <label class="abaLabel d-block" *ngIf="false">Account Receivable management Billed per client</label>
              </div>
            </div>
            <div class="col-md-2 col-xl-2">
              <label class="abaLabel" *ngIf="additionalFeature.CustomerFeaturePrice">${{additionalFeature.CustomerFeaturePrice}}</label>
              <label class="abaLabel" *ngIf="!additionalFeature.CustomerFeaturePrice">${{additionalFeature.UnitPrice}}</label>
            </div>
            <div class="col-md-4 col-xl-3 abaPdng_rgt_20" align="right">
              <mat-button-toggle-group name="fontStyle" #group="matButtonToggleGroup" [(value)]="additionalFeature.IsSubscribed">
                <mat-button-toggle [value]="true" (change)="manageSubscribedFeatures(additionalFeature,true)">Enable</mat-button-toggle>
                <mat-button-toggle [value]="false" (change)="manageSubscribedFeatures(additionalFeature,false)">Disable</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        <!--end of Addditional Features -->
      </div>
    </div>
  </div>
  <!--end of Subscription Body-->
  <!--Subscription Footer  color="accent"   *ngIf="IsModified"-->
  <div class="subscriptionFooter" *ngIf="IsModified">
    <button mat-stroked-button class="abaMrgn_rgt_15" (click)="getUnModifiedDetails()">Discard</button>
    <button mat-flat-button (click)="manageFeatures()">Save</button>
  </div>
  <!--end of Subscription Footer-->
</div>
<div class="abaBody_Container ErrorContaier" *ngIf="!HasFullAccess">
  <div class="notFoundContent">
    <div class="notFoundData">
      <i class="abaImgIcon64" icon-name="iNoRecords-xl"></i>
      <label class="abaHeader4">This page can't be viewed</label>
      <label class="abaHeader7 abaInfoLabel">The page you are trying to view can't be displayed.</label>
      <label class="abaHeader7 abaInfoLabel text-center">It may have been deleted or you don't have permission to view it right now.</label>
    </div>
  </div>
</div>
<!--end of Subscription Management Container Html-->
