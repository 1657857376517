import { Component } from '@angular/core';
import { CONSTANTS } from '../CONSTANTS';
import { GlobalConfig } from '../Global/GlobalConfig';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'component-header',
  templateUrl: '../../views/Layout/componentHeader.component.html'
})
export class componentHeaderComponent {
    routeParams: any;
    routeUrl: any = this.globalConfig.getCookie('RoutingUrl');
    routeToStaffPortal: String = this.routeUrl + "/Dashboard/#!/DefaultDashboard" || "";
    subscriptionUrl: any;
    screenlockInterval: any;
    //Using to Display Profile Menu In Header
    subscriptionUser: String = this.globalConfig.getCookie('SubscriptionUser');
    subscriptionEmail: String = this.globalConfig.getCookie('UserName');
    isInvoicePaymentIntimation: any = "";
    InvoicePaymentIntimationSubscriptionId: any = "";
    hasStaffPortalAccess = false;

    constructor(private globalConfig: GlobalConfig, private route: ActivatedRoute) {
    }

    ngOnInit() {
        if (window.location.host.indexOf("localhost") > -1) {
            if (!this.globalConfig.getCookie('SubscricptionId') ) {
                this.globalConfig.setCookie('SubscricptionId', CONSTANTS.SUBSCRIPTIONID, 1, false);
            }
            if (!this.globalConfig.getCookie('SubscricptionSwitchUrl') ) {
                this.globalConfig.setCookie('SubscricptionSwitchUrl', CONSTANTS.SUBSCRIPTIONSWITCHURL, 1, false);
          }
        }

        if (this.globalConfig.getCookie("isInvoicePaymentIntimation")) {
          this.hasStaffPortalAccess = false;
          document.cookie = "Token=" + ";domain=" + CONSTANTS.DOMAIN + ";path=/";
        } else {
          this.hasStaffPortalAccess = true;
        }
        this.route.queryParams.subscribe(params => {
            this.routeParams = params;
        });
        this.isInvoicePaymentIntimation = this.routeParams.isInvoicePaymentIntimation;
        this.InvoicePaymentIntimationSubscriptionId = this.routeParams.SubscriptionId;
        if (this.isInvoicePaymentIntimation == "true" || this.isInvoicePaymentIntimation == true) {
            this.globalConfig.setCookie("isInvoicePaymentIntimation", "true", 1, true);
            this.globalConfig.setCookie("InvoicePaymentIntimationSubscriptionId", this.InvoicePaymentIntimationSubscriptionId, 1, true);
            this.globalConfig.setCookie("testing", "true", 1, true);
        }
        //this.runFunction();
    }
  
    userProfileMenuOpen() {
        document.getElementById("userProfileMenu").style.display = "block";
        document.getElementById("profileMenu_Overlay").style.display = "block";
    }

    userProfileMenuClose() {
        document.getElementById("userProfileMenu").style.display = "none";
        document.getElementById("profileMenu_Overlay").style.display = "none";
    }

    onLogout() {
        this.subscriptionUrl = this.globalConfig.getCookie('SubscricptionSwitchUrl');
        var DomainName = this.subscriptionUrl.substring(0, this.subscriptionUrl.indexOf(".com"));
        window.location.href = DomainName + '.com/user/Logout';
    }
}
