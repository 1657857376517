/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./componentHeader.component";
import * as i3 from "../Global/GlobalConfig";
import * as i4 from "@angular/router";
var styles_componentHeaderComponent = [];
var RenderType_componentHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_componentHeaderComponent, data: {} });
export { RenderType_componentHeaderComponent as RenderType_componentHeaderComponent };
function View_componentHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-md-4 user_ProfileContent profile_PortalSwitch"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userProfileMenuClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "abaLabel abaWhite abaPdng_tp_35"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Staff Portal"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.routeToStaffPortal, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_componentHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 31, "nav", [["class", "navbar navbar-expand-sm fixed-top aba_ComponentHeader abaPdng_0 ababrdr_btm_1 shadow-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 30, "div", [["class", "col-lg-12 col-md-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 29, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xl-6 col-lg-6 col-md-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "label", [["class", "abaLabel abaPdng_tp_22 abaPdng_lft_15"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hi, here's what you need to know"])), (_l()(), i0.ɵeld(6, 0, null, null, 25, "div", [["class", "col-xl-6 col-lg-6 col-md-7"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "header-content profileAvatar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["class", "abaIcon"], ["icon-name", "iCircleUser-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userProfileMenuOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 17, "div", [["class", "userProfileMenu"], ["id", "userProfileMenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["class", "user_ProfileHeader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "abaIcon"], ["icon-name", "iCircleUser-xl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 4, "span", [["class", "abaPdng_lft_10 abaPdng_tp_15"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "label", [["class", "abaLabel abaPrimary"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "label", [["class", "abaLabel d-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), (_l()(), i0.ɵeld(18, 0, null, null, 8, "div", [["class", "row user_ProfileMenuSection"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 5, "div", [["class", "col-md-4  user_ProfileContent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userProfileMenuClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "i", [["class", "abaImgIcon defaultIcon"], ["icon-name", "iLogout-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 0, "i", [["class", "abaImgIcon ActiveIcon"], ["icon-name", "iActiveLogout-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "label", [["class", "abaLabel d-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Log Out"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_componentHeaderComponent_1)), i0.ɵdid(26, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(27, 0, null, null, 0, "div", [["class", "profileMenu_Overlay"], ["id", "profileMenu_Overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userProfileMenuClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 1, "a", [["class", "header-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 0, "i", [["class", "abaIcon"], ["icon-name", "iNotification-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 1, "a", [["class", "header-content "]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 0, "i", [["class", "abaIcon"], ["icon-name", "iMessage-md"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hasStaffPortalAccess; _ck(_v, 26, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionUser; _ck(_v, 15, 0, currVal_0); var currVal_1 = _co.subscriptionEmail; _ck(_v, 17, 0, currVal_1); }); }
export function View_componentHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "component-header", [], null, null, null, View_componentHeaderComponent_0, RenderType_componentHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.componentHeaderComponent, [i3.GlobalConfig, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var componentHeaderComponentNgFactory = i0.ɵccf("component-header", i2.componentHeaderComponent, View_componentHeaderComponent_Host_0, {}, {}, []);
export { componentHeaderComponentNgFactory as componentHeaderComponentNgFactory };
