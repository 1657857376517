import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalConfig } from './Scripts/Global/GlobalConfig';
import { CONSTANTS } from './Scripts/CONSTANTS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html', 
})
export class AppComponent {
    currentPageLoading: boolean = true;
    abaMenuView: boolean = false;
    loaderMessage: string = '';
    gif: string = 'aloha_Loader.gif';
    loadAutoSignup: boolean;
    subscriptionUrl: any;
    routeParams: any;

    constructor(private location: Location, private router: Router, private route: ActivatedRoute, private globalConfig: GlobalConfig) {
        this.route.queryParams.subscribe(params => {
            this.routeParams = params;
        });
        
        // within our constructor on updating route string.
        router.events.subscribe(val => {
            if (location.path().toLowerCase().indexOf("newsubscriber") > -1) {
                this.loadAutoSignup = true;
            } else if (this.location.path().toLowerCase().indexOf("isinvoicepaymentintimation") > -1) {
                if (this.routeParams && Object.keys(this.routeParams).length > 0) {
                    this.loadAutoSignup = false;
                }
            } else {
                this.loadAutoSignup = false;
            }
        });
    }

    runFunction() {
        this.subscriptionUrl = this.globalConfig.getCookie('SubscricptionSwitchUrl');
        if (!(this.subscriptionUrl)) {
            window.location.href = CONSTANTS.REDIRECT_TO_LOGIN_URL;
        }
    }
    
    ngOnInit() {
        if (window.location.host.indexOf("localhost") > -1) {
            if (!this.globalConfig.getCookie('SubscricptionId')) {
                this.globalConfig.setCookie('SubscricptionId', CONSTANTS.SUBSCRIPTIONID, 1, false);
            }
            if (!this.globalConfig.getCookie('SubscricptionSwitchUrl')) {
                this.globalConfig.setCookie('SubscricptionSwitchUrl', CONSTANTS.SUBSCRIPTIONSWITCHURL, 1, false);
            }
        }
        this.runFunction();
        setInterval(() => {
            this.runFunction();
        }, 750);
    }
}
