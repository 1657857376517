import { Component } from '@angular/core';
import * as moment from 'moment';
@Component({
    selector: 'app-dateconversions',
    template:''
})
export class DateConversionsComponent {

    convertDateToMMMMDDYYYY(date: string) {
        if (date) {
            return moment(date).format("MMMM DD, YYYY");
        }
        return null;
    }
    convertDateToMMMDDYYYY(date: string) {
        if (date) {
            return moment(date).format("MMM DD, YYYY");
        }
        return null;
    }
    convertDateToMMDDYYYY(date: string) {
        if (date) {
            return moment(date).format("MM/DD/YYYY");
        }
        return null;
    }
    convertDateToMMMM_YYYY(date: string) {
        if (date) {
            return moment(date).format("MMMM YYYY");
        }
        return null;
    }
    convertDateToHMMSS(date: string) {
        if (date) {
            return moment(date).format("h:mm:ss a");
        }
        return null;
    }
    getNumberOfDaysBetweenDates(date1: string, date2: string) {
        if (date1 && date2) {
            var a = moment(date1);
            var b = moment(date2);
            return a.diff(b, 'days');
        }
        return null;
    }
}
