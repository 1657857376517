<div class="abaBody_Container abaCustomContainer" style=" margin: 0px 0px 30px;">
  <div class="text-center" style="padding:1%;"><img src="assets/SASS/Images/aloha-logo.png" height="65" width="250" /></div>
</div>
<div class="text-center"><h3 class="abaBold abaMrgn_tp_20 abaMrgn_btm_40">Terms & Conditions</h3></div>
<div style=" margin-left: 10%; margin-right: 10%;">
  <p>By using our automatic payments feature, you agree to our terms & conditions. </p>
  <strong>1. Authorization for Auto-Debit Payments using credit card </strong>
  <p>By enabling automatic payments feature you are authorizing us to process auto-debit payments, you agree to allow Aloha Practice Management, LLC to initiate monthly debit transactions from your bank account. This authorization applies to payments due based on the invoices we generate and will remain in effect until you disable the automatic payment feature as outlined in Section 9.</p>
  <strong>2. Invoice Generation and Payment Schedule </strong>
  <p>We will generate an invoice at the beginning of each month, detailing the amount due for that month’s subscription.<br />Payments are due on the 15th of each month. Your credit card will be debited automatically on this date. </p>
  <strong>3. Payment Details </strong>
  <p>You authorize Aloha Practice Management, LLC to debit your credit card for the amount specified on each monthly invoice. It is your responsibility to ensure that your credit card has sufficient funds or credit limit to cover these payments. If we are unable to collect the payment due to insufficient funds or other reasons, it is your responsibility to process the payment manually. ‍Customer must pay all fees in (US currency) with Visa, MasterCard, Discover and American Express cards. </p>
  <strong>4. Fees </strong>
  <p>If your payment is returned due to insufficient funds or any other reason, you will be responsible for any returned item fees charged by your bank. </p>
  <strong>5. Processing Time </strong>
  <p>Payments debited from your account will be reflected on your card statement immediately. We are not responsible for delays or issues caused by your issuer’s processing times. </p>
  <strong>6. User Responsibilities </strong>
  <p>Customer is responsible for providing complete and accurate billing and contact information to AlohaABA<sup>TM</sup> and notifying AlohaABA<sup>TM</sup> of any changes to such information. </p>
  <strong>7. Limitation of Liability  </strong>
  <p>We are not liable for any issues arising from payment processing, including but not limited to errors by Stripe or other third parties. Our liability is limited to the maximum extent permitted by law. </p>
  <strong>8. Security and Privacy </strong>
  <p>Stripe employs advanced security technologies to protect payment information, including encryption and secure servers.  </p>
  <strong>9. Disabling Automatic Payment feature </strong>
  <p>On disabling the automatic payment feature from subscription portal, it’s your responsibility to process the payments manually before the due dates. </p>
  <strong>10. Contact Information </strong>
  <p>For questions or concerns regarding automatic payments, please contact us at:<br />Email: support@alohaaba.com<br />Phone: (254) 427-9038</p>
  <strong>11. Compliance </strong>
  <p>Our third-party payment processor (Stripe) complies with all applicable laws and regulations regarding payment processing and data protection. Stripe is also compliant with PCI-DSS standards for handling credit card information. </p>
  <strong>12. Amendments </strong>
  <p>We may update these terms and conditions periodically. Any changes will be posted on same screen. Continued use of our services after changes are made constitutes acceptance of the revised terms. </p>
</div>
<section class="section-copyright">
  <div class="footer-copyright-content text-center">Copyright © 2018-2024 Aloha Practice Management, LLC</div>
</section>
