export const environment = {
    production: false,
    GLOBAL_URL: "https://global-aloha-api.alohapractice.com/",
    ADMIN_PORTAL_API_URL: "https://www.qa-alohaap-api.alohapractice.com/",
    SUBSCRIPTIONSWITCHURL: "https://qa2-aloha.alohapractice.com/Dashboard/",
    LOGIN_URL: "https://production-identity.alohapractice.com/user/RedirectToLogin",
    LOGOUT_URL: "https://production-identity.alohapractice.com/user/Logout",
    SUBSCRIPTIONID: "a3f30146-87cd-4a07-ad82-e183f42d5eed",
    DOMAIN: ".alohapractice.com"
};
