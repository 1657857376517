import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewSubscriberService } from './NewSubscriber.service';
import { MatDialog } from '@angular/material';
import { CONSTANTS } from '../CONSTANTS';
import { AppComponent } from '../../app.component';
var NewSubscriberComponent = /** @class */ (function () {
    function NewSubscriberComponent(NewSubscriberService, parent, dialog) {
        this.NewSubscriberService = NewSubscriberService;
        this.parent = parent;
        this.dialog = dialog;
        this.testpattern = new RegExp("[^a-zA-Z 0-9!%@#^$&*|?~()-_`><.+=,/\"\'{}[]|:;\?\>\<\\]", "ig");
        //"^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{1,50}$";
        this.pattern = "^([a-zA-Z 0-9]){1,50}$";
        this.phoneNumberPattern = "^([0-9]){7,10}$";
        this.siteUrlPattern = "^([a-zA-Z0-9]){1,50}$";
        this.hide = true;
        this.formToggle = true;
        this.newSubscriberFormGroupArray = [
            this.accountInfoForm = new FormGroup({
                firstName: new FormControl('', [
                    Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
                ]),
                lastName: new FormControl('', [
                    Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
                ]),
                email: new FormControl('', [
                    Validators.required, Validators.email
                ]),
                phone: new FormControl('', [Validators.pattern(this.phoneNumberPattern)]),
                organizationName: new FormControl('', [
                    Validators.required, Validators.pattern(this.pattern), Validators.minLength(1), Validators.maxLength(50)
                ]),
                siteUrl: new FormControl('', []),
                acronym: new FormControl('', [
                    Validators.required, Validators.pattern(this.siteUrlPattern), Validators.minLength(1), Validators.maxLength(50)
                ]),
                submitted: new FormControl('', [])
            }),
        ];
        this.fieldNames = {
        //  firstName: "First Name",
        //   lastName: "Last Name",
        //   email: "Email",
        //   phone: "Phone",
        //    organizationName: "Organization Name",
        //    acronym: "Site URL",
        };
        this.wizardTabDataTargets = ["#newSubscription_AccountInfo", "#newSubscription_OrgInfo", "#newSubscription_Setup"];
        this.wizardTabs = [
            {
                tabName: "ACCOUNT INFO",
                tabIcon: "&#10112;",
                dataTarget: this.wizardTabDataTargets[0],
                isEdit: false,
                isActive: true,
                isValidatedSuccess: false,
                isSetupLoading: false,
                isSetupActive: false
            },
            {
                tabName: "ORGANIZATION INFO",
                tabIcon: "&#10113;",
                dataTarget: "",
                isEdit: false,
                isActive: false,
                isValidatedSuccess: false,
                isSetupLoading: false,
                isSetupActive: false
            },
            {
                tabName: "SETUP",
                tabIcon: "&#10114;",
                dataTarget: "",
                isEdit: false,
                isActive: false,
                isValidatedSuccess: false,
                isSetupLoading: false,
                isSetupActive: false
            }
        ];
        this.currentActiveTab = this.wizardTabDataTargets[0];
        this.showBackButton = false;
        this.showNextButton = true;
        this.showFinishButton = false;
        this.siteUrlSuffix = CONSTANTS.DOMAIN;
        this.staticData = {
        //  paymentLoadingGifName: "aba_Loader.gif",
        //  alohaLoadingGifName: "aloha_Loader.gif",
        //  loadingMessage: "Please wait while we process your request."
        };
        this.subscriberCreationStatus = "";
        this.formErrors = {
            errors: {},
            errorFields: [],
        };
        this.AddNewCustomer = function () {
            var _this = this;
            var currentTabIndex = this.wizardTabDataTargets.indexOf(this.currentActiveTab);
            //for (var i = 0; i < this.newSubscriberFormGroupArray.length; i++) {
            for (var i = 0; i <= currentTabIndex; i++) {
                if (this.currentActiveTab == this.wizardTabDataTargets[i]) {
                    this.newSubscriberFormGroupArray[i].submitted = true;
                    this.formErrors.submitted = true;
                }
                this.formErrors.errorFields = [];
                this.formErrors.errors = {};
                try {
                    if (this.newSubscriberFormGroupArray[i].invalid) {
                        for (var j in this.newSubscriberFormGroupArray[i].controls) {
                            if (this.newSubscriberFormGroupArray[i].controls[j].errors) {
                                this.newSubscriberFormGroupArray[i].errors = {};
                                if (this.newSubscriberFormGroupArray[i].controls[j].errors.required) {
                                    this.newSubscriberFormGroupArray[i].errors.required = true;
                                    this.formErrors.errors.required = true;
                                    break;
                                }
                                else if (this.newSubscriberFormGroupArray[i].controls[j].errors.pattern) {
                                    this.formErrors.errors.pattern = true;
                                    this.formErrors.errorFields.push(this.fieldNames[j]);
                                    //break;
                                }
                                else if (this.newSubscriberFormGroupArray[i].controls[j].errors.email) {
                                    this.formErrors.errors.pattern = true;
                                    this.formErrors.errorFields.push(this.fieldNames[j]);
                                    //break;
                                }
                            }
                        }
                        this.formErrors.errorFieldNames = this.formErrors.errorFields.join(", ");
                        break;
                    }
                }
                catch (e) {
                    console.log("EXCEPTION in AddNewCustomer", e);
                }
                if (!this.newSubscriberFormGroupArray[i].invalid && this.currentActiveTab == this.wizardTabDataTargets[i]) {
                    this.parent.currentPageLoading = true;
                    if (currentTabIndex == i) {
                        if (currentTabIndex == 0) {
                            this.NewSubscriberService.isEmailValid(this.accountInfoForm.value.email).subscribe(function (data) {
                                if (data && data.status == 200 && data.body) {
                                    _this.parent.currentPageLoading = false;
                                    if (currentTabIndex == _this.wizardTabDataTargets.length - 2) {
                                        _this.wizardTabs[i].isEdit = false;
                                    }
                                    if (currentTabIndex < _this.wizardTabDataTargets.length - 2) {
                                        _this.wizardTabs[i].isEdit = true;
                                    }
                                    if (i >= 0 && currentTabIndex <= _this.wizardTabDataTargets.length - 3) {
                                        _this.showBackButton = true;
                                        _this.showNextButton = true;
                                    }
                                    if (currentTabIndex == _this.wizardTabDataTargets.length - 3) {
                                        _this.showNextButton = false;
                                        _this.showFinishButton = true;
                                    }
                                    _this.wizardTabs[i].isActive = false;
                                    _this.wizardTabs[i].isValidatedSuccess = true;
                                    _this.currentActiveTab = _this.wizardTabDataTargets[i + 1];
                                    _this.wizardTabs[i + 1].isActive = true;
                                    _this.formErrors.submitted = false;
                                }
                                else {
                                    _this.parent.currentPageLoading = false;
                                    _this.formErrors.errors.duplicate = true;
                                    _this.formErrors.errorFields.push(_this.accountInfoForm.value.email);
                                    _this.formErrors.errorFieldNames = _this.accountInfoForm.value.email;
                                }
                            }, function (err) {
                                console.log("Error in isEmailValid......", err);
                            });
                        }
                        if (currentTabIndex == this.wizardTabDataTargets.length - 2) {
                            //this.parent.currentPageLoading = true;
                            var subscriberDetails = {
                            // "firstName": this.accountInfoForm.value.firstName,
                            // "lastName": this.accountInfoForm.value.lastName,
                            //  "email": this.accountInfoForm.value.email,
                            //   "phoneNumber": this.accountInfoForm.value.phone,
                            //    "organisationName": this.organizationInfoForm.value.organizationName,
                            //    "siteUrl": this.organizationInfoForm.value.acronym + this.siteUrlSuffix,
                            //    "acronym": this.organizationInfoForm.value.acronym,
                            //     "subscriptionId": ""
                            };
                            this.NewSubscriberService.sendCustomerSignupDetails(subscriberDetails).subscribe(function (data) {
                                if (data && data.status == 200 && data.body) {
                                    _this.parent.currentPageLoading = false;
                                    if (data.body.status) {
                                        _this.wizardTabs[i].isEdit = false;
                                        _this.showBackButton = false;
                                        _this.showNextButton = false;
                                        _this.showFinishButton = false;
                                        _this.wizardTabs[i].isActive = false;
                                        _this.wizardTabs[i].isValidatedSuccess = true;
                                        _this.currentActiveTab = _this.wizardTabDataTargets[i + 1];
                                        _this.wizardTabs[i + 1].isActive = true;
                                        _this.formErrors.submitted = false;
                                        var _loop_1 = function (i_1) {
                                            setTimeout(function () {
                                                _this.wizardTabs[i_1].isSetupLoading = true;
                                            }, i_1 * 400);
                                            setTimeout(function () {
                                                _this.wizardTabs[i_1].isSetupActive = true;
                                                _this.wizardTabs[i_1].isSetupLoading = false;
                                                if (i_1 == _this.wizardTabs.length - 1) {
                                                    _this.subscriberCreationStatus = "success";
                                                }
                                            }, i_1 * 1000);
                                        };
                                        for (var i_1 = 0; i_1 < _this.wizardTabs.length; i_1++) {
                                            _loop_1(i_1);
                                        }
                                    }
                                    else {
                                        //this.parent.currentPageLoading = false;
                                        _this.formErrors.errors.duplicate = true;
                                        _this.formErrors.errorFields.push(_this.organizationInfoForm.value.acronym);
                                        _this.formErrors.errorFieldNames = _this.organizationInfoForm.value.acronym;
                                    }
                                }
                                else {
                                    _this.subscriberCreationStatus = "fail";
                                    _this.parent.currentPageLoading = false;
                                }
                            }, function (err) {
                                _this.subscriberCreationStatus = "fail";
                                _this.parent.currentPageLoading = false;
                                console.log("Error in sendCustomerSignupDetails......", err);
                            });
                        }
                    }
                    break;
                }
            }
        };
        this.showWizardTab = function (tabIndex) {
            if (tabIndex >= 0 && this.wizardTabs[tabIndex].isEdit) {
                for (var i = 0; i < this.wizardTabs.length; i++) {
                    if (i >= tabIndex) {
                        this.wizardTabs[i].isActive = false;
                        this.wizardTabs[i].isValidatedSuccess = false;
                        this.wizardTabs[i].isEdit = false;
                    }
                    this.wizardTabs[i].isSetupActive = false;
                    this.wizardTabs[i].isSetupLoading = false;
                }
                this.formErrors.submitted = false;
                this.wizardTabs[tabIndex].isActive = true;
                this.wizardTabs[tabIndex].isEdit = false;
                this.wizardTabs[tabIndex].isValidatedSuccess = false;
                this.currentActiveTab = JSON.parse(JSON.stringify(this.wizardTabDataTargets[tabIndex]));
                if (tabIndex >= 0 && tabIndex <= this.wizardTabDataTargets.length - 3) {
                    this.showBackButton = true;
                    this.showNextButton = true;
                    if (tabIndex == 0) {
                        this.showBackButton = false;
                    }
                }
                if (tabIndex < this.wizardTabDataTargets.length - 2) {
                    this.showFinishButton = false;
                }
            }
        };
        this.backFunction = function () {
            this.showWizardTab(this.wizardTabDataTargets.indexOf(this.currentActiveTab) - 1);
        };
        this.organizationNameChange = function () {
            if (this.organizationInfoForm.value.organizationName) {
                var orgNameArray = this.organizationInfoForm.value.organizationName.split(" ");
                if (orgNameArray.length > 0) {
                    var tempSiteUrl = "";
                    for (var i = 0; i < orgNameArray.length; i++) {
                        tempSiteUrl += orgNameArray[i].charAt(0);
                    }
                    tempSiteUrl = tempSiteUrl.toLowerCase();
                }
                this.organizationInfoForm.patchValue({ acronym: tempSiteUrl });
            }
        };
    }
    NewSubscriberComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.parent.currentPageLoading = false;
            _this.parent.loaderMessage = _this.staticData.loadingMessage;
        });
    };
    return NewSubscriberComponent;
}());
export { NewSubscriberComponent };
