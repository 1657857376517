/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./abaLoader.component";
var styles_abaLoaderComponent = [];
var RenderType_abaLoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_abaLoaderComponent, data: {} });
export { RenderType_abaLoaderComponent as RenderType_abaLoaderComponent };
function View_abaLoaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "abaLoader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "d-block"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "label", [["class", "abaLabel"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "../assets/SASS/Images/", _co.gif, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_abaLoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_abaLoaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_abaLoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-Loader", [], null, null, null, View_abaLoaderComponent_0, RenderType_abaLoaderComponent)), i0.ɵdid(1, 311296, null, 0, i2.abaLoaderComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var abaLoaderComponentNgFactory = i0.ɵccf("app-Loader", i2.abaLoaderComponent, View_abaLoaderComponent_Host_0, { message: "message", isLoading: "isLoading", gif: "gif" }, {}, []);
export { abaLoaderComponentNgFactory as abaLoaderComponentNgFactory };
