<component-header></component-header>
<!--Error 404 Container Html-->
<div class="abaBody_Container ErrorContaier">
  <div class="notFoundContent">
    <div class="notFoundData">
      <i class="abaImgIcon64" icon-name="iNoRecords-xl"></i>
      <label class="abaHeader4">This page can't be viewed</label>
      <label class="abaHeader7 abaInfoLabel">The page you are trying to view can't be displayed.</label>
      <label class="abaHeader7 abaInfoLabel">It may have been deleted or you don't have permission to view it right now.</label>
    </div>
  </div>
</div>
