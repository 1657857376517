import { Component } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { CONSTANTS } from '../CONSTANTS';

@Component({
    selector: 'app-globalconfig',
    template: ''
})

export class GlobalConfig {
    setCookie(cname: String, cvalue: String, exdays: any, isDomainEnable: any) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toDateString();
        var domain = isDomainEnable ? ";domain=" + CONSTANTS.DOMAIN : "";
        document.cookie = cname + "=" + cvalue + ";expires=" + expires + ";path=/" + domain;
  }

    getCookie(cname: String) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        if (cname == "subscriptionPortalAccess") {
            return "True";
        }
        return '';
    }
};
