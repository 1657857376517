
<div id="InvoiceReceiptPdf">
  <!--Invoice PDF Html-->
  <div id="printSectionId" class="page">
    <div style="height:100%;position: relative;margin: 0;box-shadow: 0;font-size: 22px !important;font-family: Helvetica Neue !important;font-weight: normal;height: auto;line-height: 20px !important;letter-spacing: 0.6px;color: #333333 !important;" *ngIf="pendingInvoiceResponse.InvoiceDetails&&pendingInvoiceResponse.InvoiceDetails[0]">
      <div class="aloha-componentView-body">
        <div style="display:block;">
          <div style="display:block;float:left;padding-left:6px;padding-top:5%;"><img src="assets/SASS/Images/aloha-logo.png" height="78" width="300" /></div>
          <div style="float:right;padding-top:5%;">
            <div style="font-size:28px;font-weight:600;color:#333333;font-family:Helvetica Neue,sans serif;"><span>AlohaABA Statement</span></div>
            <div style="font-size:14px;font-weight:300;color:#333333;padding-top:15px;font-family:Helvetica Neue,sans serif;">Customer ID :<span style="float:right;color:#333333;font-family:Helvetica Neue,sans serif;">{{pendingInvoiceResponse.InvoiceDetails[0].Customer_Id}}</span></div>
            <div style="font-size:14px;font-weight:300;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;">Statement Number :<span style="float:right;font-weight:300;color:#333333;font-family:Helvetica Neue,sans serif;">{{pendingInvoiceResponse.InvoiceDetails[0].Statement_Number}}</span></div>
            <div style="font-size:14px;font-weight:300;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;">Statement Date :<span style="float:right;font-family:Helvetica Neue,sans serif;">{{invoiceChargesList && invoiceChargesList.invoiceDate}}</span></div>
            <div style="font-size:14px;font-weight:300;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;">Due Date :<span style="float:right;font-family:Helvetica Neue,sans serif;">{{invoiceChargesList && invoiceChargesList.invoiceDueDate}}</span></div>
          </div>
        </div>
        <div style="padding-left:6px;padding-top:17%;">
          <div style="font-size:14px;font-weight:600;color:#333333;font-family:Helvetica Neue,sans serif;">Bill to:</div>
          <div style="font-size:14px;font-weight:600;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;">{{pendingInvoiceResponse.InvoiceDetails[0].Customer_Name}}</div>
          <div style="font-size:14px;font-weight:300;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;"><span>{{pendingInvoiceResponse.InvoiceDetails[0].Street}}</span></div>
          <div style="font-size:14px;font-weight:300;color:#333333;padding-top:3px;font-family:Helvetica Neue,sans serif;"><span>{{pendingInvoiceResponse.InvoiceDetails[0].City}},</span><span style="padding-left:5px">{{pendingInvoiceResponse.InvoiceDetails[0].State}},</span><span style="padding-left:5px">{{pendingInvoiceResponse.InvoiceDetails[0].Country}} {{pendingInvoiceResponse.InvoiceDetails[0].Zip}}</span></div>
        </div>
        <br /><br />
        <div style=" background-color:#eef1f6;padding-bottom:10px;padding-top:10px">
          <span style="font-size:14px;font-weight:600;color: #333333;padding-left:20px;text-align:left;font-family:Helvetica Neue,sans serif;">Invoice for the month of {{invoiceChargesList && invoiceChargesList.invoiceMonth}}</span>
        </div>
        <div style="padding-top:5px;overflow:hidden">
          <!--Features Charges Table Html-->
          <table style="width:100%;border-collapse:separate; border-spacing:0px;">
            <thead>
              <tr>
                <th style="width:55%;height:40px;font-size:14px;font-weight:600;border-bottom:1px solid #ebeff5;color:#333333;text-align:left;padding-left:20px;font-family:Helvetica Neue,sans serif;">Description</th>
                <th style="width:10%;height:40px;font-size:14px;font-weight:600;border-bottom:1px solid #ebeff5;color:#333333;text-align:left;font-family:Helvetica Neue,sans serif;">Quantity</th>
                <th style="width:15%;height:40px;font-size:14px;font-weight:600;border-bottom:1px solid #ebeff5;color:#333333;text-align:right;font-family:Helvetica Neue,sans serif;">Unit Price</th>
                <th style="width:20%;height:40px;font-size:14px;font-weight:600;border-bottom:1px solid #ebeff5;color:#333333;text-align:right;padding-right:20px;font-family:Helvetica Neue,sans serif;">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width:55%;border-bottom:1px solid #ebeff5;height:40px;font-size:14px;font-weight:600;padding-left:20px;color:#CDB776;font-family:Helvetica Neue,sans serif;">FEATURES CHARGES</td>
                <td style="width:10%;border-bottom:1px solid #ebeff5;height:40px;"></td>
                <td style="width:15%;border-bottom:1px solid #ebeff5;height:40px;"></td>
                <td style="width:20%;border-bottom:1px solid #ebeff5;height:40px;font-size:14px;font-weight:600;text-align:right;padding-right:20px;color:#333333;font-family:Helvetica Neue,sans serif;">${{invoiceChargesList && invoiceChargesList.totalAmount|number:'.2-2'}}</td>
              </tr>
              <tr *ngFor="let feature of invoiceFeaturesList;let i=index">
                <td style="width:55%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;padding-left:40px" *ngIf="feature.FeatureName != null">
                  <span style="padding-right:20px;font-family:Helvetica Neue,sans serif;">{{i+1|number:'2.0-0'}}</span><span style="font-family:Helvetica Neue,sans serif;">{{feature.InvoiceDescription}}</span>
                </td>
                <td style="width:10%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;font-family:Helvetica Neue,sans serif;" *ngIf="feature.FeatureName != null">{{feature.Quantity}}</td>
                <td style="width:15%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;text-align:right;font-family:Helvetica Neue,sans serif;" *ngIf="feature.FeatureName != null">${{feature.CustomerFeaturePrice|number:'.2-2'}}</td>
                <td style="width:20%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;text-align:right;padding-right:20px;font-family:Helvetica Neue,sans serif;" *ngIf="feature.FeatureName != null">${{feature.LineItemAmount|number:'.2-2'}}</td>
              </tr>
              <tr>
                <td style="width:55%;height:40px;border-bottom:1px solid #ebeff5;font-size:14px;font-weight:600;color:#333333;padding-left:40px;font-family:Helvetica Neue,sans serif;">Sub Total</td>
                <td style="width:10%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:15%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:20%;height:40px;border-bottom:1px solid #ebeff5;font-size:14px;font-weight:600;color:#333333;text-align:right;padding-right:20px;font-family:Helvetica Neue,sans serif;">${{invoiceChargesList && invoiceChargesList.totalAmount|number:'.2-2'}}</td>
              </tr>
              <tr *ngIf="invoiceChargesList && invoiceChargesList.discountAmount > 0">
                <td style="width:55%;height:40px;border-bottom:1px solid #ebeff5;font-size:14px;font-weight:300;color:#333333;padding-left:40px;font-family:Helvetica Neue,sans serif;">Total Invoice Discount</td>
                <td style="width:10%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:15%;height:40px;border-bottom:1px solid #ebeff5;font-size:14px;font-weight:300;color:#333333;text-align:right;font-family:Helvetica Neue,sans serif;"></td>
                <td style="width:20%;height:40px;border-bottom:1px solid #ebeff5;font-size:14px;font-weight:300;color:#333333;text-align:right;padding-right:20px;font-family:Helvetica Neue,sans serif;">-${{invoiceChargesList && invoiceChargesList.discountAmount|number:'.2-2'}}</td>
              </tr>
            </tbody>
          </table>
          <!--End Features Charges Table Html-->
          <!--Additional Charges Table Html-->
          <table style="width:100%;border-collapse:separate;border-spacing:0px;" *ngIf="pendingInvoiceResponse.InvoiceAdditionalCharges?.length > 0">
            <tbody>
              <tr>
                <td style="width:55%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:25%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:20%;height:40px;border-bottom:1px solid #ebeff5;"></td>
              </tr>
              <tr>
                <td style="width:55%;height:40px;border-bottom:1px solid #ebeff5;font-weight:600;font-size:14px;padding-left:20px;color:#CDB776;font-family:Helvetica Neue,sans serif;">ADDITIONAL ADJUSTMENTS</td>
                <td style="width:25%;height:40px;border-bottom:1px solid #ebeff5;"></td>
                <td style="width:20%;height:40px;border-bottom:1px solid #ebeff5;font-weight:600;font-size:14px;padding-right:20px;text-align:right;font-family:Helvetica Neue,sans serif;">
                  <span *ngIf="invoiceChargesList && invoiceChargesList.additionalChargesTotal>=0">${{invoiceChargesList && invoiceChargesList.additionalChargesTotal|number:'.2-2'}}</span>
                  <span *ngIf="invoiceChargesList && invoiceChargesList.additionalChargesTotal<0">-${{-(invoiceChargesList && invoiceChargesList.additionalChargesTotal)|number:'.2-2'}}</span>
                </td>
              </tr>
              <tr *ngFor=" let additionalCharge of pendingInvoiceResponse.InvoiceAdditionalCharges;let j=index">
                <td style="width:50%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;padding-left:40px;">
                  <span style="padding-right:22px;font-family:Helvetica Neue,sans serif;">{{j+1|number:'2.0-0'}}</span><span style="font-family:Helvetica Neue,sans serif;">{{additionalCharge.Title}}</span>
                </td>
                <td style="width:25%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;font-family:Helvetica Neue,sans serif;">{{additionalCharge.Description}}</td>
                <td style="width:20%;height:40px;font-size:14px;font-weight:300;border-bottom:1px solid #ebeff5;color:#333333;padding-right:20px;text-align:right;font-family:Helvetica Neue,sans serif;">
                  <span *ngIf="additionalCharge.Amount>=0">${{additionalCharge.Amount|number:'.2-2'}}</span>
                  <span *ngIf="additionalCharge.Amount<0">-${{-(additionalCharge.Amount)|number:'.2-2'}}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!--End Of Additional Charges Table Html-->
          <div style="background-color:#000000;padding-top:10px;padding-bottom:10px;page-break-inside:avoid;">
            <span style="color:#FFFFFF;font-size:14px;font-weight:600;padding-left:52%;font-family:Helvetica Neue,sans serif;">TOTAL FOR THIS STATEMENT</span>
            <span style="color:#FFFFFF;font-size:14px;font-weight:600;padding-right:20px;float:right;font-family:Helvetica Neue,sans serif;">
              <span *ngIf="invoiceChargesList && invoiceChargesList.totalAmountBilled>=0">${{invoiceChargesList && invoiceChargesList.totalAmountBilled|number:'.2-2'}}</span>
              <span *ngIf="invoiceChargesList && invoiceChargesList.totalAmountBilled<0">-${{-(invoiceChargesList && invoiceChargesList.totalAmountBilled)|number:'.2-2'}}</span>
            </span>
          </div>
          <div style="padding-top:30px;padding-left:20px;display:block" *ngIf="pendingInvoiceResponse.InvoiceDetails[0].Note!=null">
            <div style="font-size:14px;font-weight:600;color:#333333;font-family:Helvetica Neue,sans serif;">Notes:</div>
            <div style="font-size:10px;font-weight:500;color:#333333;padding-top:4px;word-break:break-all;font-family:Helvetica Neue,sans serif;">{{pendingInvoiceResponse.InvoiceDetails[0].Note}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of Invoice PDF Html-->
  <!----PaymentReceipt HTML----->
  <div id="PaymentReceiptDetails" *ngIf="showPaymentReciept" class="page">
    <div class="aloha-componentView" style="height:100%">
      <div class="aloha-componentView-body">
        <div style="float:right;padding-top:8%;"><img src="assets/SASS/Images/aloha-logo.png" height="75" width="300" /></div>
        <div style="padding-left:6px;padding-top:30%;" *ngIf="pendingInvoiceResponse.InvoiceDetails&&pendingInvoiceResponse.InvoiceDetails[0]">
          <div style="font-size:14px;font-weight:600;color:#333333;font-family:Helvetica,sans-serif;">To,</div>
          <div style="font-size:14px;font-weight:600;color:#333333;padding-top:3px;font-family:Helvetica,sans-serif;">{{pendingInvoiceResponse.InvoiceDetails[0].Customer_Name}}</div>
          <div style="font-size:14px;font-weight:normal;color:#333333;padding-top:3px;font-family:Helvetica,sans-serif">{{pendingInvoiceResponse.InvoiceDetails[0].Street}}, {{pendingInvoiceResponse.InvoiceDetails[0].City}},{{pendingInvoiceResponse.InvoiceDetails[0].State}},{{pendingInvoiceResponse.InvoiceDetails[0].Country}}</div>
          <!--<div style="font-size:14px;font-weight:normal;color:#333333;padding-top:3px;font-family:Helvetica,sans-serif">{{pendingInvoiceResponse.InvoiceDetails[0].PhoneNumber}}</div>-->
        </div>
        <br /><br />
        <div style="font-size:20px;font-weight:600;color:#333333;border-bottom:1px solid #A8A8A8;text-align:center;padding-bottom:20px;font-family:Helvetica,sans-serif">ONLINE PAYMENT RECEIPT</div>
        <div style="padding-top:5px;overflow:hidden" *ngIf="paymentReceiptDetails">
          <div style="font-size:14px;font-weight:normal;color:#333333;word-break:break-all;word-wrap:break-word;padding:20px 10px 50px 10px;line-height:30px;font-family:Helvetica,sans-serif">Thank you. Please quote this receipt no. for any future related to this transactions.</div>
          <div style="font-size:14px;font-weight:600;color:#333333;padding-left:20px;padding-bottom:20px;font-family:Helvetica,sans-serif">TRANSACTION DETAILS</div>
          <table style="width:100%;border-collapse:separate; border-spacing:0px;border:1px solid #A8A8A8;">
            <tbody>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Name</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.Name}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Email ID</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.EmailId}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Customer ID</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.CustomerId}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Order ID</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.StatementNumber}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Mode of Payment Method</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.ModeOFPayment}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Payment Date</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.PaymentDate}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Payment Time</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.PaymentTime}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;padding-left:20px;font-family:Helvetica,sans-serif">Transaction Reference Number</td>
                <td style="font-size:14px;color:#000000;font-weight:500;width:50%;border-bottom:1px solid #A8A8A8;height:40px;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">{{paymentReceiptDetails.TransactionReferenceNumber}}</td>
              </tr>
              <tr>
                <td style="font-size:14px;font-weight:600;width:50%;height:40px;color:#FFFFFF;background-color:#A8A8A8;padding-left:20px;font-family:Helvetica,sans-serif">Total Amount</td>
                <td style="font-size:14px;font-weight:600;width:50%;height:40px;color:#FFFFFF;background-color:#A8A8A8;text-align:right;padding-right:10px;font-family:Helvetica,sans-serif">${{paymentReceiptDetails.TotalAmount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!----End of PaymentReceipt HTML----->
</div>

