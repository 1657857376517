import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONSTANTS } from '../CONSTANTS';
@Injectable()
export class SubscriptionMangementService {
  constructor(private http: HttpClient) { }
  
  getSubscriptionDetails() {
    return this.http.get(CONSTANTS.GLOBAL_URL+'Api/IdentityPortal/Subscription/GetAllFeatures', { observe: 'response'});
  }
  getCurrentMonthFeatures(SubscriptionId) {
    return this.http.get(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/GetCurrentMonthFeatures/' + SubscriptionId, { observe: 'response' });
  }
  manageSubscribedFeatures(manageFeatures: any) {
    return this.http.post(CONSTANTS.GLOBAL_URL + 'Api/IdentityPortal/Subscription/ManageFeatures',manageFeatures, { observe: 'response' });
  }
  getAccessDetails(manageFeatures: any,Url) {
    return this.http.post(Url + '/Api/Security/UserAccount/GetIdentityPortalAccessDetails', manageFeatures, { observe: 'response', headers: manageFeatures });
  }

}
