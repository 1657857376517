import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-Loader',
  template: ` <div class="abaLoader" *ngIf="isLoading">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <img src="../assets/SASS/Images/{{gif}}" class="d-block" />
                  <label class="abaLabel">{{message}}</label>
                </div>
              </div>`,
  
})
export class abaLoaderComponent {
  @Input() message: string;
  @Input("isLoading") isLoading: boolean;
  @Input("gif") gif: string;
  constructor(private cd: ChangeDetectorRef) { }
  ngDoCheck() {
      if (this.isLoading == true) {
        if (document.body.classList.value.indexOf("abaDialogBlur")==-1) {
          document.body.classList.value += " abaDialogBlur ";
        }
      } else {
        document.body.classList.remove("abaDialogBlur");
      }
  }
}
